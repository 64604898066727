<div class="users-table">
  <div class="table-responsive mb-5">
    <table class="align-middle table-responsive text-truncate mb-0 table">
      <thead class="regular-18 main-color">
        <tr class="ta">
          <th class="text-center" (click)="sortUsers()" style="cursor: pointer">
            اسم
          </th>
          <th class="text-center">بريد إلكتروني</th>
          <th class="text-center">رقم التليفون</th>
          <th class="text-center">رقم التأكيد</th>
          <th class="text-center">رقم الفاتورة</th>
          <th class="text-center">تاريخ الفاتورة</th>
          <th class="text-center">تاريخ الإستحقاق</th>
          <th class="text-center">
            <div ngbDropdown>
              <button
                type="button"
                style="border: none"
                ngbDropdownToggle
                class="btn menu-button"
              >
                <span>المبلغ</span>
              </button>
              <div ngbDropdownMenu style="z-index: 9999; direction: rtl">
                <button class="dropdown-item" (click)="setMonths(1)">
                  شهر
                </button>
                <button class="dropdown-item" (click)="setMonths(3)">
                  3 شهور
                </button>
                <button
                  class="dropdown-item"
                  (click)="setMonths(undefinedValue)"
                >
                  جميع
                </button>
              </div>
            </div>
          </th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of filteredUsers; index as i">
          <td>
            <a class="main-color" [routerLink]="['/user-profile', user.id]">
              {{ user.userName }}
            </a>
          </td>
          <td class="text-center">
            {{ user.userEmail }}
          </td>
          <td class="text-center">{{ user.userPhone }}</td>
          <td class="text-center">{{ user.confirmNumber }}</td>
          <td class="text-center">{{ user.transactionId }}</td>
          <td class="text-center">{{ user.startTime }}</td>
          <td class="text-center">{{ user.endTime }}</td>
          <td class="text-center">{{ user.amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between table-footer">
    <div
      class="d-flex col-sm-6 justify-content-start gap-5 pagination-container"
    >
      <ngb-pagination
        [(collectionSize)]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refresh()"
      >
      </ngb-pagination>
      <select
        class="select"
        style="width: auto"
        [(ngModel)]="pageSize"
        (ngModelChange)="refresh()"
      >
        <option [ngValue]="5">5/صفحة</option>
        <option [ngValue]="10">10/صفحة</option>
        <option [ngValue]="15">15/صفحة</option>
      </select>
    </div>
  </div>
</div>
