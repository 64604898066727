<div class="h-100 text-center" id="login">
  <div class="mt-5">
    <img src="../../../../assets/images/Logo.png" alt="zawaj">
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <div class="dialog mt-5 mx-auto col-md-8 col-sm-12">
      <h1 class="title-large">تسجيل الدخول</h1>
      <p class="sub-title mt-1 mb-4">واجهة الإدارة</p>
      <form #loginForm="ngForm" (ngSubmit)="login()">
        <div role="group" class="mb-3">
          <div class="mb-3">
            <label for="email" class="label-16 mb-2">بريد إالكتروني</label>
            <input name="email" type="email" placeholder="اكتب بريدك الإلكتروني هنا" required [(ngModel)]="email"
              #emailInput="ngModel" email class="form-control" [ngClass]="{ 'error-border': emailInput.errors }">
          </div>
          <div>
            <label for="password" class="label-16 mb-2">كلمة المرور</label>
            <input name="password" type="password" placeholder="اكتب كلمة المرور الخاصة بك هنا" required
              [(ngModel)]="password" #passwordInput="ngModel" class="form-control"
              [ngClass]="{ 'error-border': passwordInput.errors }">
          </div>
          <div><a routerLink="/pages/forgot-password-boxed" class="btn mb-4 mt-2 p-0 btn-link main-color label-16">إعادة
              تعيين
              كلمة المرور
            </a>
          </div>
          <!-- <div class="error mt-1 mb-1"
                *ngIf="(emailInput.invalid || passwordInput.invalid) && (emailInput.dirty || emailInput.touched) && (passwordInput.dirty || passwordInput.touched)">
                <div *ngIf="(passwordInput.errors || emailInput.errors || error )">عذرًا، البريد الإلكتروني أو كلمة
                  المرور الخاصة
                  بك غير صحيحة، أو المستخدم غير موجود. يرجى التحقق من البيانات الخاصة بك.</div>
              </div> -->
          <div class="error mt-1 mb-1" *ngIf="(error) && (passwordInput.dirty || passwordInput.touched)">
            <div *ngIf="(passwordInput.errors || emailInput.errors || error )">عذرًا، البريد الإلكتروني أو كلمة
              المرور الخاصة
              بك غير صحيحة، أو المستخدم غير موجود. يرجى التحقق من البيانات الخاصة بك.</div>
          </div>
          <button [disabled]="loading" type="submit" class="btn btn-lg mt-4 button-large">تسجيل
            الدخول</button>
        </div>
      </form>


    </div>


  </div>
</div>