<div id="parameters" class="d-md-flex justify-content-between">
    <div class="d-flex flex-wrap">
        <div ngbDropdown>
            <button type="button" ngbDropdownToggle class="mb-2 me-2 btn menu-button "><span class="regular-16">إختر
                    الصفة</span></button>
            <div ngbDropdownMenu style="z-index: 9999999999;">
                <button *ngFor="let para of parameters" (click)="onButtonClick(para.parameterId)"
                    class="dropdown-item">{{para.title}}</button>
            </div>
        </div>
        <div *ngFor="let para of parameters">
            <div *ngIf="selectedPara === para.parameterId" ngbDropdown>
                <button type="button" ngbDropdownToggle (click)="onButtonClick(para.parameterId)"
                    class="mb-2 me-2 btn menu-button "><span class="regular-16">{{para.title}}</span></button>
                <div ngbDropdownMenu>
                    <button *ngFor="let value of para.values" (click)="onValueClick(value.id)"
                        class="dropdown-item">{{value.value}}</button>
                </div>
            </div>
        </div>
    </div>
</div>