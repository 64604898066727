import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-feedback-table",
  templateUrl: "./feedback-table.component.html",
  styleUrls: [
    "./feedback-table.component.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/table.scss",
  ],
})
export class FeedbackTableComponent implements OnInit {
  pagesLimit = 10;
  feedback: any[];
  UserId: string;
  page = 1;
  size = 4;
  pageSize = 5;
  @Output() collectionSize = new EventEmitter<number>();
  filter = new FormControl("");
  filteredUsers: any[] = [];
  @Input() searchText: any = "";
  constructor(private service: UsersService) {}

  filterUsers() {
    const term = this.searchText.toLowerCase().trim();
    if (term === "") {
      this.filteredUsers = this.feedback;
      location.reload();
    } else {
      this.filteredUsers = this.feedback.filter((user) => {
        const term = this.searchText.toLowerCase();
        return Object.values(user).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(term);
          }
          if (typeof value === "number") {
            const stringValue = value.toString();
            return stringValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
  }

  getAllFeedback(): void {
    this.service.getAllFeedback(this.page, this.pagesLimit).subscribe(
      (feedback) => {
        this.feedback = feedback;
        this.filteredUsers = feedback;
        this.collectionSize.emit(feedback.length);
        console.log(feedback);
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  refresh() {
    this.feedback
      .map((payment, i) => ({ id: i + 1, ...payment }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
  isAscending = true;

  sortUsers() {
    this.feedback.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      const compareResult = nameA.localeCompare(nameB);
      return this.isAscending ? compareResult : -compareResult; // Use the flag from the outer scope
    });
    this.isAscending = !this.isAscending; // Toggle the flag for the next click
  }
  ngOnInit(): void {
    this.getAllFeedback();
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredUsers = this.feedback;
    } else {
      this.filterUsers();
    }
  }
}
