<div class="user-profile">
  <section>
    <div class="info d-flex justify-content-between">
      <div class="d-flex align-items-center gap-5">
        <div
          class="d-flex flex-column gap-3 justify-content-center align-items-center"
        >
          <img
            class="profile-img"
            *ngIf="!images[0]"
            src="https://th.bing.com/th/id/OIP.aeFgd5pGDsEoc6OD87TQHAHaHa?rs=1&pid=ImgDetMain"
            alt="profile"
          />
          <img
            *ngIf="images[0]"
            class="profile-img"
            [src]="path + images[0]"
            alt="profile-img"
          />
        </div>
        <div class="d-flex flex-column gap-2">
          <p class="regular-20 main-color mb-0" style="font-weight: bold">
            {{ user.name }}
          </p>
          <p class="regular-16 main-color mb-0">{{ user.age }}</p>
          <!-- <p class="regular-16 main-color mb-0">إشتراك للمحترفين</p> -->
        </div>
      </div>

      <div class="d-flex blockUser align-items-center gap-3">
        <app-send-message [UserId]="UseId"></app-send-message>
        <app-block-user
          [isBlocked]="isBlocked"
          [UserId]="UseId"
        ></app-block-user>
      </div>
    </div>
    <div class="mt-2">
      <div class="d-flex gap-5">
        <p class="regular-16 main-color">{{ user.email }}</p>
        <p class="regular-16 main-color">{{ user.city }}</p>
      </div>
      <div class="d-flex gap-3">
        <p class="regular-16 main-color">{{ user.about || "about" }}</p>
        <p class="regular-16 main-color">{{ user.maritalStatus }}</p>
      </div>
    </div>

    <div class="d-flex gap-3 mt-5">
      <div>
        <p class="main-color regular-16" style="font-weight: bold">التدخين</p>
        <p class="main-color regular-16" *ngIf="user.isSmoking === true">
          يدخن
        </p>
        <p class="main-color regular-16" *ngIf="user.isSmoking === false">
          لا يدخن
        </p>
      </div>
      <div>
        <p class="main-color regular-16" style="font-weight: bold">الجنس</p>
        <p class="main-color regular-16">
          {{ user.gender }}
        </p>
      </div>
      <div>
        <p class="main-color regular-16" style="font-weight: bold">الوزن</p>
        <p class="main-color regular-16">
          {{ user.weight }}
        </p>
      </div>
      <div>
        <p class="main-color regular-16" style="font-weight: bold">الطول</p>
        <p class="main-color regular-16">
          {{ user.height }}
        </p>
      </div>
      <div *ngFor="let para of parameters">
        <p class="main-color regular-16" style="font-weight: bold">
          {{ para.parameterName }}
        </p>
        <p class="main-color regular-16">{{ para.valueName }}</p>
      </div>
      <app-edit-user-profile-parameters
        [UseId]="UseId"
      ></app-edit-user-profile-parameters>
    </div>
  </section>

  <section class="d-flex justify-content-start gap-4">
    <img
      *ngFor="let imageUrl of images"
      class="user-images"
      [src]="path + imageUrl"
      alt="Image"
    />
  </section>

  <section class="chats d-flex flex-column align-items-start gap-4">
    <p class="main-color regular-24 mb-5">الدردشات</p>
    <div
      class="likes-container d-flex flex-column gap-4 align-items-center justify-content-between"
    >
      <div class="likes-content d-flex align-items-center gap-5">
        <div
          class="d-flex flex-column gap-3 justify-content-center align-items-center"
        >
          <img
            class="profile-img"
            src="https://i.pinimg.com/564x/d4/90/de/d490de284bde5edbfffd24e9f3db48a9.jpg"
            alt=""
          />
        </div>
        <div class="d-flex flex-column gap-2">
          <p class="regular-16 main-color mb-0">
            <span>اسم </span><span>منذ 5 ايام</span>
          </p>
          <p class="regular-16 main-color mb-0">إشتراك للمحترفين</p>
        </div>
      </div>
      <div class="likes-content d-flex align-items-center gap-5">
        <div
          class="d-flex flex-column gap-3 justify-content-center align-items-center"
        >
          <img
            class="profile-img"
            src="https://i.pinimg.com/564x/d4/90/de/d490de284bde5edbfffd24e9f3db48a9.jpg"
            alt=""
          />
        </div>
        <div class="d-flex flex-column gap-2">
          <p class="regular-16 main-color mb-0">
            <span>اسم </span><span>منذ 5 ايام</span>
          </p>
          <p class="regular-16 main-color mb-0">إشتراك للمحترفين</p>
        </div>
      </div>
    </div>
  </section>

  <section class="likes">
    <p class="main-color regular-24 mb-5">المستخدمون الذين أعجبوا به</p>
    <div
      *ngFor="
        let whoLiked of whoLiked.slice(0, showAllDivs ? whoLiked.length : 6)
      "
      class="likes-container d-flex align-items-center justify-content-between"
    >
      <div class="likes-content d-flex align-items-center gap-4">
        <div
          class="d-flex flex-column gap-3 justify-content-center align-items-center"
        >
          <img class="profile-img" [src]="path + whoLiked.images[0]" alt="" />
        </div>
        <div class="d-flex flex-column gap-2">
          <p class="regular-16 main-color mb-0">
            <span>{{ whoLiked.name }} </span>
            <!-- <span>منذ 5 ايام</span> -->
          </p>
          <!-- <p class="regular-16 main-color mb-0">إشتراك للمحترفين</p> -->
        </div>
      </div>
    </div>
    <div
      class="mt-5 more"
      style="width: 20%"
      *ngIf="whoLiked && whoLiked.length > 6"
    >
      <button class="btn btn-outline" (click)="toggleShowAllDivs()">
        {{ showAllDivs ? "عرض أقل" : "عرض المزيد" }}
      </button>
    </div>
  </section>

  <section class="likes">
    <p class="main-color regular-24 mb-5">المستخدمون اللذين أعجبهم</p>
    <div class="likes-container d-flex align-items-center gap-4">
      <div *ngFor="let like of liked.slice(0, showAllDivs ? liked.length : 6)">
        <div class="likes-content d-flex align-items-center gap-4">
          <div
            class="d-flex flex-column gap-3 justify-content-center align-items-center"
          >
            <img class="profile-img" [src]="path + like.images[0]" alt="" />
          </div>
          <div class="d-flex flex-column gap-2">
            <p class="regular-16 main-color mb-0">
              <span>{{ like.name }} </span>
              <!-- <span>منذ 5 ايام</span> -->
            </p>
            <!-- <p class="regular-16 main-color mb-0">إشتراك للمحترفين</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 more" style="width: 20%" *ngIf="liked && liked.length > 6">
      <button class="btn btn-outline" (click)="toggleShowAllDivs()">
        {{ showAllDivs ? "عرض أقل" : "عرض المزيد" }}
      </button>
    </div>
  </section>
</div>
