import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../auth/auth.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private apiUrl: string = `${environment.API_URL}/api/Analytics`;


  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  // all users
  getNumUsers(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsers`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // all users by date
  getNumUsersByDay(date: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsersForDay/?date=${date}`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  // by publicity
  getPublicActivities(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetPercentageLoggedUsers`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // publicity by date
  getPublicActivitiesByDay(date: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetPercentageLoggedUsersForDay/?date=${date}`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // by gender
  getUsersGender(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsersByGender`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // gender by date
  getUsersGenderByDay(date: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsersByGenderForDay/?date=${date}`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // by city
  getUsersByCity(cityId: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsersByCity/?cityId=${cityId}`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // city by date
  getUsersCityByDay(cityId: string, date: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByCityForDay/?date=${date}&cityId=${cityId}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  
  
  //by area
  getUsersByArea(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetNumUsersByArea`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // Area by date
  getUsersAreaByDay(date: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByAreaForDay/?date=${date}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  // by marital status
  getUsersByMaritalStatus(lang:string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByMaritalStatus`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
  // marital status by day
  getUsersByMaritalStatusByDay(
    lang:string,
    date: any
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByMaritalStatusForDay/?date=${date}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  // parameters


  // parameterById 
  getParameterById(Id: string, lang:string ="ar"): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByParameter?ParameterId=${Id}&lang=${lang}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getParameterByWeek(Id: string,weekNumber:number): Observable<any> {
    return this.http
    .get<any>(
      `${this.apiUrl}/GetNumUsersByParameter/${weekNumber}?ParameterId=${Id}`,
      {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      }
    )
    .pipe(
      tap((response) => {
        console.log(response);
      }),
      catchError((error) => {
        console.error("An error occurred:", error);
        return throwError(error);
      })
    );
  }
  // parameters by day
  getParameterByIdByDay(
    Id: string,
    date: any
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/GetNumUsersByParameterForDay/?ParameterId=${Id}&date=${date}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }


}
