<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <p class="main-color regular-20">{{chartTitle}}</p>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
            <div style="width: 70%;">
                <form class="row row-cols-sm-auto">
                    <div class="col-12">
                        <div class="input-group">
                            <input class="form-control" type="date" ngbDatepicker name="d2" #c2="ngModel"
                                [(ngModel)]="model" (ngModelChange)="onDateSelected()">
                            <!-- <input class="form-control" placeholder="mm/dd/yyyy" name="d2" #c2="ngModel"
                                [(ngModel)]="model" ngbDatepicker #d2="ngbDatepicker"
                                (ngModelChange)="onDateSelected()" />
                            <button class="btn" (click)="d2.toggle()" type="button"><svg width="14" height="16"
                                    viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.44 1.4112H10.5V0.1568C10.5 0.07056 10.437 0 10.36 0H9.38C9.303 0 9.24 0.07056 9.24 0.1568V1.4112H4.76V0.1568C4.76 0.07056 4.697 0 4.62 0H3.64C3.563 0 3.5 0.07056 3.5 0.1568V1.4112H0.56C0.25025 1.4112 0 1.69148 0 2.0384V15.0528C0 15.3997 0.25025 15.68 0.56 15.68H13.44C13.7498 15.68 14 15.3997 14 15.0528V2.0384C14 1.69148 13.7498 1.4112 13.44 1.4112ZM12.74 14.2688H1.26V6.8208H12.74V14.2688ZM1.26 5.488V2.8224H3.5V3.7632C3.5 3.84944 3.563 3.92 3.64 3.92H4.62C4.697 3.92 4.76 3.84944 4.76 3.7632V2.8224H9.24V3.7632C9.24 3.84944 9.303 3.92 9.38 3.92H10.36C10.437 3.92 10.5 3.84944 10.5 3.7632V2.8224H12.74V5.488H1.26Z"
                                        fill="#B9B9B9" />
                                </svg></button> -->

                        </div>
                    </div>
                </form>
            </div>
            <div>
                <p class="main-color regular-18"> تاريخ</p>
            </div>
        </div>
        <div class="container">
            <div class="d-flex justify-content-between">
                <div style="display: flex;flex-direction: column;">
                    <p *ngFor="let item of count" class="main-color regular-18">{{item}}</p>
                </div>
                <p class="main-color regular-18">{{ model }}</p>
            </div>
        </div>
    </div>
</ng-template>
<button (click)="openmodal(content)" class="border-0 btn">
    <a class="me-1 main-color" style="font-size: 20px ;"><ins>إظهار كافة المعلومات</ins></a>
</button>