<div class="users-table">
  <div class="table-responsive mb-5">
    <table class="align-middle table-responsive text-truncate mb-0 table">
      <thead>
        <tr class="ta">
          <th></th>
          <th class="text-center" (click)="sortUsers()" style="cursor: pointer">
            اسم
          </th>
          <th class="text-center">بريد إلكتروني</th>
          <th class="text-center">وزن</th>
          <th class="text-center">طول</th>
          <th class="text-center">تحقق</th>
          <th class="text-center" *ngFor="let para of parameters">
            {{ para.title }}
          </th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of filteredUsers; index as i">
          <td scope="row">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
            </div>
          </td>
          <td>
            <a class="main-color" [routerLink]="['/user-profile', user.id]">
              <ngb-highlight
                [result]="user.name"
                [term]="filter.value"
              ></ngb-highlight>
            </a>
          </td>
          <td>
            <ngb-highlight
              [result]="user.email"
              [term]="filter.value"
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="user.weight | number"
              [term]="filter.value"
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="user.height | number"
              [term]="filter.value"
            ></ngb-highlight>
          </td>
          <td>
            <a class="main-color" [routerLink]="['/user-profile', user.id]">
              <ngb-highlight
                [result]="user.verificationStatus"
                [term]="filter.value"
              ></ngb-highlight>
            </a>
          </td>
          <td *ngFor="let param of user.parameters">
            <ng-container>
              <ngb-highlight
                [result]="param.valueName"
                [term]="filter.value"
              ></ngb-highlight>
            </ng-container>
          </td>
          <ng-container
            *ngFor="
              let i of [].constructor(
                parametersLength - user.parameters?.length
              )
            "
          >
            <td></td>
          </ng-container>
          <td class="text-center">
            <div
              class="btn-group-sm btn-group d-flex justify-content-end"
              style="gap: 30px"
            >
              <app-edit-user
                [UserId]="user.id"
                [name]="user.name"
                [email]="user.email"
              ></app-edit-user>
              <app-delete-user [UserId]="user.id"></app-delete-user>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between table-footer">
    <div
      class="d-flex col-sm-6 justify-content-start gap-5 pagination-container"
    >
      <ngb-pagination
        id="page"
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refresh()"
      >
      </ngb-pagination>
      <select
        class="select"
        style="width: auto"
        [(ngModel)]="pageSize"
        (ngModelChange)="refresh()"
      >
        <option [ngValue]="5">5/صفحة</option>
        <option [ngValue]="10">10/صفحة</option>
        <option [ngValue]="15">15/صفحة</option>
      </select>
    </div>
    <!-- <div class="d-flex col-md-6 col-sm-6 gap-3">
            <button class="btn message-btn btn-outline">إرسال رسالة عبر النشرة
                الإخبارية</button>
            <button class="btn message-btn button-large">إرسال إشعار للمستخدمين</button>
        </div> -->
  </div>
</div>
