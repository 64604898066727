<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <h1 class="modal-title main-color title">تحرير الصفة</h1>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <div class="container" *ngIf="[0, 1].includes(selectedTypeId)">
            <button class="btn" (click)="addDiv()">أضف صفة</button>
        </div>
        <form (ngSubmit)="updateParameter()" class="p-0">
            <div class="container">
                <div class="container" ngbDropdown style="direction: rtl;">
                    <p class="main-color regular-16">نوع الصفة :
                        <span *ngIf="!selectedTypeId">{{ type }}</span>
                        <span *ngIf="selectedTypeId">{{ selectedTypeName }}</span>
                    </p>
                    <button type="button" ngbDropdownToggle class="mb-2 me-2 btn menu-button">
                        <span class="regular-16">اختر نوع الصفة</span>
                    </button>
                    <div ngbDropdownMenu style="direction: rtl; text-align: start;">
                        <button *ngFor="let type of types" type="button" (click)="onTypeClick(type.parameterType)" class="dropdown-item">
                            {{ type.name }}
                        </button>
                    </div>
                </div>
                <input class="form-control" placeholder="الصفة بالعربية" [(ngModel)]="parameter_ar"
                    name="parameter_ar" />
                <input class="form-control" placeholder="الصفة بالإنجليزية" [(ngModel)]="parameter_en"
                    name="parameter_en" />
                <hr class="hr" />
                <div class="container" *ngFor="let value of values">
                    <div>
                        <input class="form-control" id="value_ar_{{ value.valueId }}" placeholder="القيمة بالعربية"
                            [(ngModel)]="value.value_ar" name="value_ar_{{ value.valueId }}" />
                    </div>
                    <div>
                        <input class="form-control" id="value_en_{{ value.valueId }}" placeholder="القيمة بالإنجليزية"
                            [(ngModel)]="value.value_en" name="value_en_{{ value.valueId }}" />
                    </div>
                    <hr class="hr" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn button-large">حفظ</button>
            </div>
        </form>
    </div>
</ng-template>

<button (click)="openmodal(content)" class="border-0 btn p-0">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20H21" stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
            stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</button>