import { Component, TemplateRef, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-add-moderator",
  templateUrl: "./add-moderator.component.html",
  styleUrls: [
    "./add-moderator.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AddModeratorComponent {
  UserId: string;
  role: string = "Supervisor";
  Name: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
  PhoneNumber: string;
  error: string;

  constructor(
    private modalService: NgbModal,
    private authServise: AuthenticationService
  ) {}
  register(): void {
    this.authServise
      .addAdmin(
        this.role,
        this.Name,
        this.Email,
        this.Password,
        this.PhoneNumber,
        this.ConfirmPassword
      )
      .subscribe(
        (response) => {
          // this.UserId = response.userId;
          console.log("Registration successful");
          // console.log("this is the user id" + this.UserId);
          console.log(response);
          this.modalService.dismissAll();
          // const token = response.token;
          // this.authServise.storeToken(token);
          location.reload();
          //  this.setAdmin();
        },
        (error) => {
          this.error =
            error?.message || "An error occurred during adding new user.";
          console.log(error);
        }
      );
  }

  // setAdmin() {
  //   this.authServise
  //     .setUserRole(this.UserId, this.RoleName)
  //     .subscribe((response) => {
  //       console.log("role is set to admin successfully" + this.RoleName);
  //     });
  // }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }
}
