import {
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ParametersService } from "src/app/services/parameters/parameters.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-delete-parameter",
  templateUrl: "./delete-parameter.component.html",
  styleUrls: [
    "./delete-parameter.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteParameterComponent {
  @Input() ParameterId: string;

  constructor(
    private modalService: NgbModal,
    private paramService: ParametersService,
    private location: Location
  ) {}

  deleteParameter(): void {
    this.paramService.deleteParameter(this.ParameterId).subscribe(
      () => {
        console.log(`Parameter ${this.ParameterId} deleted`);
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.error(
          "An error occurred while deleting parameter:",
          error,
          this.ParameterId
        );
      }
    );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnSubmit() {
    this.deleteParameter();
  }
}
