<ng-template #content let-modal class="rounded-2">
  <div class="modal-header" *ngIf="!isBlocked">
    <h1 class="modal-title main-color title">حظر المستخدم</h1>
    <div>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
  </div>
  <div class="modal-header" *ngIf="isBlocked">
    <h1 class="modal-title main-color title">مستخدم محظور</h1>
    <div>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
  </div>
  <div class="modal-body" *ngIf="!isBlocked">
    <p class="regular-16 main-color mt-2">هل تريد حظر المستخدم ؟</p>
    <form (ngSubmit)="blockUser()" class="p-0">
      <div class="modal-footer">
        <button type="submit" class="btn button-large">حظر</button>
        <button
          type="button"
          (click)="modal.dismiss('Cross click')"
          class="btn btn-outline main-color"
        >
          إلغاء
        </button>
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="isBlocked">
    <p class="regular-16 main-color mt-2">هل تريد فك الحظر عن المستخدم ؟</p>
    <form (ngSubmit)="UnBlockUser()" class="p-0">
      <div class="modal-footer">
        <button type="submit" class="btn button-large">فك الحظر</button>
        <button
          type="button"
          (click)="modal.dismiss('Cross click')"
          class="btn btn-outline main-color"
        >
          إلغاء
        </button>
      </div>
    </form>
  </div>
</ng-template>

<button
  (click)="openModal(content)"
  class="btn btn-outline"
  style="width: max-content"
  *ngIf="isBlocked"
>
  فك الحظر
</button>
<button
  (click)="openModal(content)"
  class="btn btn-outline"
  style="width: max-content"
  *ngIf="!isBlocked"
>
  حظر المستخدم
</button>
