<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Solid
        </h5>
        <button class="mb-2 me-2 btn btn-primary">Primary
        </button>
        <button class="mb-2 me-2 btn btn-secondary">Secondary
        </button>
        <button class="mb-2 me-2 btn btn-success">Success
        </button>
        <button class="mb-2 me-2 btn btn-info">Info
        </button>
        <button class="mb-2 me-2 btn btn-warning">Warning
        </button>
        <button class="mb-2 me-2 btn btn-danger">Danger
        </button>
        <button class="mb-2 me-2 btn btn-focus">Focus
        </button>
        <button class="mb-2 me-2 btn btn-alternate">Alt
        </button>
        <button class="mb-2 me-2 btn btn-light">Light
        </button>
        <button class="mb-2 me-2 btn btn-dark">Dark
        </button>
        <button class="mb-2 me-2 btn btn-link">link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Color Transition
        </h5>
        <button class="mb-2 me-2 btn-transition btn btn-outline-primary">Primary
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-secondary">Secondary
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-success">Success
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-info">Info
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-warning">Warning
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-danger">Danger
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-focus">Focus
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-alternate">Alt
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-light">Light
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-dark">Dark
        </button>
        <button class="mb-2 me-2 btn-transition btn btn-outline-link">link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Color Transition No Borders
        </h5>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-primary">Primary
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-secondary">Secondary
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-success">Success
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-info">Info
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-warning">Warning
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-danger">Danger
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-focus">Focus
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-alternate">Alt
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-light">Light
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-dark">Dark
        </button>
        <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-link">link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Hover Shine
        </h5>
        <button class="mb-2 me-2 btn-hover-shine btn btn-primary">Primary
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-secondary">Secondary
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-success">Success
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-info">Info
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-warning">Warning
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-danger">Danger
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-focus">Focus
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-alternate">Alt
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-light">Light
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-dark">Dark
        </button>
        <button class="mb-2 me-2 btn-hover-shine btn btn-link">link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Active State
        </h5>
        <button class="mb-2 me-2 btn btn-primary active">Primary
        </button>
        <button class="mb-2 me-2 btn btn-secondary active">Secondary
        </button>
        <button class="mb-2 me-2 btn btn-success active">Success
        </button>
        <button class="mb-2 me-2 btn btn-info active">Info
        </button>
        <button class="mb-2 me-2 btn btn-warning active">Warning
        </button>
        <button class="mb-2 me-2 btn btn-danger active">Danger
        </button>
        <button class="mb-2 me-2 btn btn-focus active">Focus
        </button>
        <button class="mb-2 me-2 btn btn-alternate active">Alt
        </button>
        <button class="mb-2 me-2 btn btn-light active">Light
        </button>
        <button class="mb-2 me-2 btn btn-dark active">Dark
        </button>
        <button class="mb-2 me-2 btn btn-link active">link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Disabled State
        </h5>
        <button class="mb-2 me-2 btn btn-primary disabled" disabled>Primary
        </button>
        <button class="mb-2 me-2 btn btn-secondary disabled" disabled>Secondary
        </button>
        <button class="mb-2 me-2 btn btn-success disabled" disabled>Success
        </button>
        <button class="mb-2 me-2 btn btn-info disabled" disabled>Info
        </button>
        <button class="mb-2 me-2 btn btn-warning disabled" disabled>Warning
        </button>
        <button class="mb-2 me-2 btn btn-danger disabled" disabled>Danger
        </button>
        <button class="mb-2 me-2 btn btn-focus disabled" disabled>Focus
        </button>
        <button class="mb-2 me-2 btn btn-alternate disabled" disabled>Alt
        </button>
        <button class="mb-2 me-2 btn btn-light disabled" disabled>Light
        </button>
        <button class="mb-2 me-2 btn btn-dark disabled" disabled>Dark
        </button>
        <button class="mb-2 me-2 btn btn-link disabled" disabled>link
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Block Level
        </h5>
        <div class="d-grid gap-2">
          <button class="mb-2 me-2 btn btn-primary btn-lg">Block Large
          </button>
          <button class="mb-2 me-2 btn btn-primary">Block Normal
          </button>
          <button class="mb-2 me-2 btn btn-primary btn-sm">Block Small
          </button>
      </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Wider
        </h5>
        <div class="text-center">
          <button class="btn-wide mb-2 me-2 btn btn-primary btn-lg">Wider Large
          </button>
          <button class="btn-wide mb-2 me-2 btn btn-primary">Wider Normal
          </button>
          <button class="btn-wide mb-2 me-2 btn btn-primary btn-sm">Wider Small
          </button>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Sizing
        </h5>
        <div class="text-center">
          <button class="mb-2 me-2 btn btn-primary btn-lg">Large
          </button>
          <button class="mb-2 me-2 btn btn-primary">Normal
          </button>
          <button class="mb-2 me-2 btn btn-primary btn-sm">Small
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
