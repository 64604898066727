import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
@Component({
  selector: "app-blocked-users-table",
  templateUrl: "./blocked-users-table.component.html",
  styleUrls: [
    "./blocked-users-table.component.sass",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/table.scss",
  ],
})
export class BlockedUsersTableComponent implements OnInit {
  blockedUsers: any[] = [];
  filteredUsers: any[] = [];
  page = 1;
  pageSize = 10;
  pagesLimit = 10;
  @Input() searchText: any = "";
  @Output() collectionSize = new EventEmitter<number>();

  constructor(private service: UsersService) {}

  getAllBlockaedUsers() {
    this.service.getAllBlockedUsers(this.page, this.pagesLimit).subscribe(
      (blocked:[]) => {
        this.blockedUsers = blocked;
        this.filteredUsers = blocked;
        console.log("blockeddd " , this.blockedUsers);
        this.collectionSize.emit(blocked.length);
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  filterUsers() {
    const term = this.searchText.toLowerCase().trim();
    if (term === "") {
      this.filteredUsers = this.blockedUsers;
      location.reload();
    } else {
      this.filteredUsers = this.blockedUsers.filter((user) => {
        const term = this.searchText.toLowerCase();
        return Object.values(user).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(term);
          }
          if (typeof value === "number") {
            const stringValue = value.toString();
            return stringValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
  }
  sortUsers() {
    this.blockedUsers.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      const compareResult = nameA.localeCompare(nameB);
      return this.isAscending ? compareResult : -compareResult; // Use the flag from the outer scope
    });
    this.isAscending = !this.isAscending; // Toggle the flag for the next click
  }
  refresh() {
    this.blockedUsers
      .map((user, i) => ({ id: i + 1, ...user }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
  isAscending = true;

  ngOnInit(): void {
    this.getAllBlockaedUsers();
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredUsers = this.blockedUsers;
    } else {
      this.filterUsers();
    }
  }
}
