<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <h1 class="modal-title main-color title">حذف المشرف</h1>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="deleteModerator()" class="p-0">
            <div class="modal-footer">
                <button type="submit" class="btn button-large">حذف</button>
            </div>
        </form>
    </div>
</ng-template>
<button (click)="openmodal(content)" class="border-0 btn p-0">
    <!-- <i class="pe-7s-trash"></i> -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6H5H21" stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
            stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</button>