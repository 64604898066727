import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  collectionSize: number = 0;
  searchText: any = "";
  filteredUsers: any = [];
  users: any = [];

  constructor() {}

  filterUsers() {
    this.filteredUsers = this.users.filter((user) => {
      const term = this.searchText.toLowerCase();
      return Object.values(user).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        }
        if (typeof value === "number") {
          const stringValue = value.toString();
          return stringValue.toLowerCase().includes(term);
        }
        return false;
      });
    });
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredUsers = this.users;
      console.log(this.filteredUsers);
    } else {
      this.filterUsers();
    }
  }
  ngOnInit(): void {}
}
