import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "app-block-user",
  templateUrl: "./block-user.component.html",
  styleUrls: [
    "./block-user.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
})
export class BlockUserComponent implements OnInit {
  @Input() UserId: string;
  @Input() isBlocked: boolean;

  constructor(
    private modalService: NgbModal,
    private service: UsersService,
    private router: Router
  ) {}

  blockUser() {
    this.service.blockUser(this.UserId).subscribe(
      () => {
        console.log("user" + this.UserId + "blocked ");
        this.modalService.dismissAll();
        this.router.navigate(["/"]);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  UnBlockUser(){
    this.service.UnBlockUser(this.UserId).subscribe(
      () => {
        console.log("user" + this.UserId + "unBlocked ");
        this.modalService.dismissAll();
        this.router.navigate(["/"]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  close() {
    this.modalService.dismissAll();
    location.reload();
  }

  ngOnInit() {}
}
