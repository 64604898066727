import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/auth/auth.service";
import { first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-forgot-password-boxed",
  templateUrl: "./forgot-password-boxed.component.html",
  styleUrls: [
    "../user-pages.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/buttons.scss",
    "../user-pages.scss",
  ],
})
export class ForgotPasswordBoxedComponent implements OnInit {
  Value: string = "";
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {}

  forgot(Value: string): void {
    this.authService
      .forgotPassword(Value)
      // .pipe(first())
      .subscribe(
        (res) => {
          console.log("code sent");
          if (res.headers.status === 200) {
            this.router.navigate(["pages/reset-password-boxed"]);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
  }
}
