import { Component, Input, OnInit, Pipe, PipeTransform } from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
import { ParametersService } from "src/app/services/parameters/parameters.service";
import { DecimalPipe } from "@angular/common";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
// import { pipeline } from "stream";

interface User {
  name: string;
  email: string;
  height: number;
  weight: number;
  parameters: string[];
}
@Component({
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: [
    "./users-table.component.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/table.scss",
  ],
})
export class UsersTableComponent implements OnInit {
  users: User[];
  parameters: string[] = [];
  filteredUsers: any[] = [];
  parametersLength: number = 0;
  userParamLength: string[];
  parameterId: any | "";
  @Input() selectedParameterId: number | "";
  @Input() selectedValueId: number | null = null;
  @Input() searchText: any = "";
  oldSelectedValueId: number | null = null;
  valueId: any | null;
  lang: string = "ar";
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  filter = new FormControl("");

  constructor(
    private service: UsersService,
    private parametersService: ParametersService
  ) {}

  refresh() {
    this.filteredUsers = this.users
      .map((user, i) => ({ id: i + 1, ...user }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
    this.filterUsersByParameters();
  }
  isAscending = true;

  // getAllusers() {
  //   this.service.getAllUsers(this.page, this.pageSize).subscribe(
  //     (users) => {
  //       this.users = users;
  //       this.filteredUsers = users;
  //       this.userParamLength = users.parameters;
  //     },
  //     (error) => {
  //       console.error("An error occurred:", error);
  //     }
  //   );
  // }

  getAllParameters() {
    this.parametersService.getAllParameters().subscribe(
      (parameters) => {
        this.parameters = parameters;
        this.parametersLength = parameters.length;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.filterUsersByParameters();
    this.getAllParameters();
    this.service.getUsersCount().subscribe(
      (count) => {
        this.collectionSize = count;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  filterUsers() {
    const term = this.searchText.toLowerCase().trim(); // Ignore leading/trailing whitespace
    if (term === "") {
      this.filteredUsers = this.users; // Reset filtering
    } else {
      let allUsers = this.users;
      this.filteredUsers = allUsers.filter((user) => {
        return Object.values(user).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(term);
          }
          if (typeof value === "number") {
            const stringValue = value.toString();
            return stringValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
  }
  sortUsers() {
    this.filteredUsers?.sort((a, b) => {
      const nameA = a.name?.toLowerCase();
      const nameB = b.name?.toLowerCase();
      const compareResult = nameA?.localeCompare(nameB);
      return this.isAscending ? compareResult : -compareResult; // Use the flag from the outer scope
    });
    this.isAscending = !this.isAscending; // Toggle the flag for the next click
  }
  filterUsersByParameters() {
    if (this.selectedValueId !== this.oldSelectedValueId) {
      this.oldSelectedValueId = this.selectedValueId;
      this.service
        .getAllUsersFiltered(
          this.page,
          this.pageSize,
          this.selectedParameterId,
          this.selectedValueId,
          this.lang
        )
        .subscribe(
          (filteredwithParams) => {
            this.users = filteredwithParams;
            this.filteredUsers = filteredwithParams;
          },
          (error) => {
            console.error("An error occurred:", error);
          }
        );
    }
  }
  ngOnChanges() {
    this.filterUsers();
    this.filterUsersByParameters();
  }
}
