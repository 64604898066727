import {
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-delete-moderator",
  templateUrl: "./delete-moderator.component.html",
  styleUrls: [
    "./delete-moderator.component.sass",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteModeratorComponent {
  @Input() AdminId: string;
  constructor(
    private modalService: NgbModal,
    private service: UsersService,
    private location: Location
  ) {}

  deleteModerator(): void {
    this.service.deleteAdmin(this.AdminId).subscribe(
      () => {
        console.log(`Admin ${this.AdminId} deleted`);
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.error(
          "An error occurred while deleting Admin:",
          error,
          this.AdminId
        );
      }
    );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnSubmit() {
    this.deleteModerator();
  }
}
