<div [class]="'app-sidebar' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()" (mouseout)="sidebarHover()">
  <div class="header__pane ms-auto">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
    </button>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="text-center mt-2">
          <app-logo></app-logo>
        </div>
        <div class="v-sidebar-menu vsm-default mt-2">
          <div class="vsm-list">
            <div class="w-100 list-container">
              <button class="btn side-menu-item" routerLink="/users">
                <span class="side-menu-text w-max-content">المستخدمين</span>
              </button>
              <button class="btn side-menu-item" routerLink="/moderators">
                <span routerLinkActive="active-item" class="side-menu-text">
                  إدارة المشرف</span>
              </button>
              <button class="btn side-menu-item" routerLink="/payment">
                <span routerLinkActive="active-item" >
                  <span class="side-menu-text">إدارة الدفع</span></span>
              </button>
              <button class="btn side-menu-item" routerLink="/analytics">
                <span routerLinkActive="active-item" class="side-menu-text">
                  التحليلات</span>
              </button>
              <button class="btn side-menu-item" routerLink="/personal-parameters">
                <span routerLinkActive="active-item" class="side-menu-text">
                  الصفات الشخصية</span>
              </button>
              <button class="btn side-menu-item" routerLink="/blocked-users">
                <span routerLinkActive="active-item" class="side-menu-text">
                  المستخدمون المحظورون</span>
              </button>
               <button class="btn side-menu-item" routerLink="/feedback">
                <span routerLinkActive="active-item" class="side-menu-text">
                تقييمات المستخدمين</span>
              </button>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>