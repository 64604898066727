import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-blocked-users",
  templateUrl: "./blocked-users.component.html",
  styleUrls: [
    "./blocked-users.component.sass",
    "../../layout/styles/fonts.scss",
    "../../layout/styles/table.scss",
    "../../layout/styles/buttons.scss",
  ],
})
export class BlockedUsersComponent implements OnInit {
  searchText: any = "";
  filteredUsers: any = [];
  users: any = [];
  collectionSize: number = 0;
  constructor() {}

  filterUsers() {
    this.filteredUsers = this.users.filter((user) => {
      const term = this.searchText.toLowerCase();
      return Object.values(user).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        }
        if (typeof value === "number") {
          const stringValue = value.toString();
          return stringValue.toLowerCase().includes(term);
        }
        return false;
      });
    });
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredUsers = this.users;
      console.log(this.filteredUsers);
    } else {
      this.filterUsers();
    }
  }

  ngOnInit(): void {
    console.log("lennn", this.collectionSize);
  }
}
