<div class="users">
    <div class="justify-content-between head d-flex mb-2">
        <div>
            <h1 class="title title-large main-color">إدارة المستخدم ( {{collectionSize}} )</h1>
        </div>
        <div>
            <app-search (searchTextChanged)="searchText = $event"></app-search>
        </div>
    </div>
    <app-parameters (parameterClicked)="onParameterClick($event)"
        (valueClicked)="onValueClick($event)"></app-parameters>
    <app-users-table [searchText]="searchText" [selectedParameterId]="selectedParameterId"
        [selectedValueId]="selectedValueId"></app-users-table>
</div>