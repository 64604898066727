import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login-boxed.component.html",
  styleUrls: [
    "./login-boxed.component.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/buttons.scss",
    "../user-pages.scss",
  ],
})
export class LoginBoxedComponent implements OnInit {
  email: string;
  password: string;
  error: string;
  loading: boolean = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  login(): void {
    this.loading = true;
    this.authService.login(this.email, this.password).subscribe(
      (response) => {
        console.log(response);
        const token = response.token;
        this.authService.storeToken(token);
        // if (response.roles[0] === "Admin") {
        this.router.navigate(["/"]);
        // }
      },
      (error) => {
        this.error = error?.message || "An error occurred during login.";
      }
    ).add(() => {
      this.loading = false; // Set loading to false when the login process completes (either success or error)
    });
  }
}
