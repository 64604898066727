import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ParametersService } from "src/app/services/parameters/parameters.service";
@Component({
  selector: "app-parameters-table",
  templateUrl: "./parameters-table.component.html",
  styleUrls: [
    "./parameters-table.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/table.scss",
  ],
})
export class ParametersTableComponent implements OnInit {
  parameters: any[] = [];
  values: string[] = [];
  @Output() length = new EventEmitter<number>();
  par: string[] = [];
  filter = new FormControl("");
  filteredParams: any[] = [];
  @Input() searchText: any = "";
  constructor(private service: ParametersService) {}

  getMaxValuesLength(): number {
    return Math.max(...this.parameters.map((param) => param.values.length));
  }

  getAllParameter() {
    this.service.getAllParameters().subscribe(
      (parameters) => {
        this.parameters = parameters;
        this.filteredParams = parameters;
        this.length.emit(parameters.length) 
        console.log("paraaaaaaaa", this.parameters)
      },
      (error) => {
        console.error("An error occurred:", error);
      }
    );
  }

// filterParameters() {
//     const term = this.searchText.toLowerCase().trim();
//     if (term === "") {
//       this.filteredParams = this.parameters;
//       location.reload();
//     } else {
//       this.filteredParams = this.parameters.filter((param) => {
//         const term = this.searchText.toLowerCase();
//         return Object.values(param).some((value) => {
//           if (typeof value === "string") {
//             return value.toLowerCase().includes(term);
//           }
//           if (typeof value === "number") {
//             const stringValue = value.toString();
//             return stringValue.toLowerCase().includes(term);
//           }
//           return false;
//         });
//       });
//     }
//   }

  
 
  filterParameters() {
    const term = this.searchText.toLowerCase().trim();
    if (term === "") {
      this.filteredParams = this.parameters;
    } else {
      this.filteredParams = this.parameters.filter((param) => {
        const paramValues = Object.values(param);
        return paramValues.some((value) => {
          const stringValue = value.toString().toLowerCase();
          return stringValue.includes(term);
        });
      });
    }
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredParams = this.parameters;
    } else {
      this.filterParameters();
      console.log("hwa da ",this.filteredParams)
    }
  }
  // ngOnChanges() {
  //   this.filterParameters();
  // }
  
  ngOnInit(): void {
    this.getAllParameter();
  }
}
 