import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

@Component({
  selector: "app-users-by-marital-status-chart",
  templateUrl: "./users-by-marital-status-chart.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class UsersByMaritalStatusChartComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  @Output() count: any[];
  date: any;
  chartTitleContent = "الحالة الإجتماعية";
  Id: string = "38";
  ola: string[];
  week: number = 2;
  lang: string = "ar";

  constructor(private service: AnalyticsService) {}

  getMaritalStatus(): void {
    this.service.getUsersByMaritalStatus(this.lang).subscribe(
      (response: {
        userNotSelected: number;
        singleUsers: number;
        marriedUsers: number;
        heDivorcedUsers: number;
        sheDivorcedUsers: number;
        divorcedWithChildrenUsers: number;
        divorcedWithoutChildrenUsers: number;
        widowerUsers: number;
        others: number;
      }) => {
        const responseObject = [
          "غيرمحدد",
          "عزباء_اعزب",
          "متزوج",
          "منفصل",
          "منفصلة",
          "منفصل_بدون_اولاد",
          "منفصل_مع_اولاد",
          "ارمل_ارملة",
          "اخر",
        ];
        const userStatusLabels = [
          "Not Selected",
          "Single",
          "Married",
          "He Divorced",
          "She Divorced",
          "Divorced with Children",
          "Divorced without Children",
          "Widower",
          "Others",
        ];
        const userStatusData = [
          response?.userNotSelected,
          response?.singleUsers,
          response?.marriedUsers,
          response?.heDivorcedUsers,
          response?.sheDivorcedUsers,
          response?.divorcedWithChildrenUsers,
          response?.divorcedWithoutChildrenUsers,
          response?.widowerUsers,
          response?.others,
        ];
        console.log("marital data ", response);
        const dynamicChartData = [
          {
            data: userStatusData,
            label: "User Status",
          },
        ];

        this.dynamicChartData = dynamicChartData;
        this.barChartLabels = responseObject;
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  getMaritalStatusByDay(): void {
    this.service.getUsersByMaritalStatusByDay(this.lang, this.date).subscribe(
      (response: {
        userNotSelected: number;
        singleUsers: number;
        marriedUsers: number;
        heDivorcedUsers: number;
        sheDivorcedUsers: number;
        divorcedWithChildrenUsers: number;
        divorcedWithoutChildrenUsers: number;
        widowerUsers: number;
        others: number;
      }) => {
        this.count = [
          ...this.barChartLabels
            .slice(1)
            .map(
              (label, index) =>
                label + ": " + this.dynamicChartData[0].data[index + 1]
            ),
        ];
        console.log("dodyyyyy", this.count);
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }
  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getMaritalStatusByDay();
  }

  ngOnInit() {
    this.getMaritalStatus();
  }
}
