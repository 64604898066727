import { Component, OnInit } from "@angular/core";
// import {MatPaginatorModule} from '@angular/material/paginator';
@Component({
  selector: "app-tables-main",
  templateUrl: "./tables-main.component.html",
  styleUrls: [
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/buttons.scss",
  ],
  // imports: [MatPaginatorModule],
})
export class TablesMainComponent implements OnInit {
  // heading = 'Bootstrap 5 Tables';
  // subheading = 'Tables are the backbone of almost all web applications.';
  // icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';
  heading = "Pagination";
  subheading =
    "Basic and dynamic pagination for use in your next awesome application.";
  icon = "pe-7s-notebook icon-gradient bg-mixed-hopes";

  page = 3;
  page3 = 3;
  page4 = 4;

  currentPage = 4;

  page2 = 5;

  isDisabled = true;

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }

  constructor() {}

  ngOnInit() {}
}
