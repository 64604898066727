import { Component, OnInit, Output } from "@angular/core";
import { ParametersService } from "src/app/services/parameters/parameters.service";
@Component({
  selector: "app-chartjs",
  templateUrl: "./chartjs.component.html",
  styleUrls: [],
})
export class ChartjsComponent implements OnInit{
  heading = "التحليلات";
  @Output() Ids : string[];
  constructor(private service : ParametersService) {}


  ngOnInit(): void {
    this.getids();
  }

  getids(){
    this.service.getAllParameters().subscribe(
      (res)=>{
        this.Ids = res.map(item => item.id);
        console.log("all parameters" , this.Ids);
      },
      (err)=>{
        console.log(err)
      }
    )
  }
 
  
}
