<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <h1 class="modal-title main-color title">تحرير العضو</h1>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <form class="p-0" (ngSubmit)="updateUser()">
            <div class="mb-3">
                <div class="container">
                    <input id="name" class="form-control" placeholder="اسم" [(ngModel)]="name" name="name" />
                    <input id="name" class="form-control" placeholder="بريد إلكتروني" [(ngModel)]="email"
                        name="email" />
                    <div class="d-flex">
                        <input id="name" class="form-control edit-user-email" placeholder="بريد إلكتروني"
                            name="email" />
                        <div class="form-switch">
                            <label class="form-check-label regular-24 main-color"
                                for="flexSwitchCheckDefault">تحقق</label>
                            <input class="form-check-input regular-24" type="checkbox" id="flexSwitchCheckDefault">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn button-large">حفظ</button>
            </div>
        </form>
    </div>
</ng-template>

<button (click)="openmodal(content)" class="border-0  btn p-0">
    <!-- <i class="pe-7s-pen"></i> -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20H21" stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
            stroke="#B9B9B9" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</button>