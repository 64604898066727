import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "app-send-message",
  templateUrl: "./send-message.component.html",
  styleUrls: [
    "./send-message.component.sass",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
})
export class SendMessageComponent implements OnInit {
  @Input() UserId: string;

  constructor(
    private modalService: NgbModal,
    private service: UsersService,
    private router: Router
  ) {}
  openModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  close() {
    this.modalService.dismissAll();
    location.reload();
  }

  ngOnInit(): void {}
}
