import { Component } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
})
export class PaginationComponent {
  filtered :string[];
  users:any[];
  page = 1;
  size = 10;
  pageSize = 4;
  collectionSize = 0;

  refresh() {
    this.filtered = this.users
      .map((user, i) => ({ id: i + 1, ...user }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  selectPage(page: string) {
    this.page = parseInt(page, 10) || 1;
  }


  
}
