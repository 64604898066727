<div class="parameters-table">
  <div class="table-responsive mb-5">
    <table
      class="align-middle table-responsive text-truncate mb-0 table table-bordered"
    >
      <thead class="regular-18 main-color">
        <tr class="ta">
          <th class="text-center">حدود</th>
          <th class="text-center" [attr.colspan]="getMaxValuesLength()">
            قيمة
          </th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let para of filteredParams">
          <td>{{ para.title }}</td>
          <ng-container *ngFor="let val of para.values">
            <td>{{ val.value }}</td>
          </ng-container>
          <ng-container
            *ngFor="
              let i of [].constructor(
                getMaxValuesLength() - para.values?.length
              )
            "
          >
            <td></td>
          </ng-container>
          <td class="actions">
            <div class="d-flex gap-3 justify-content-between">
              <app-delete-parameter
                class="p-0"
                [ParameterId]="para.parameterId"
              ></app-delete-parameter>
              <app-edit-parameter
                class="p-0"
                [ParameterId]="para.parameterId"
              ></app-edit-parameter>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
