import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

@Component({
  selector: "app-users-by-area-chart",
  templateUrl: "./users-by-area-chart.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class UsersByAreaChartComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  @Output() count: any[] = [];
  date: any;
  area: string;
  chartTitleContent = "المستخدمون لكل منطقة";
  constructor(private service: AnalyticsService) {}

  getUsersByArea(): void {
    this.service.getUsersByArea().subscribe(
      (response: {
        userNotSelected: number;
        areaSelection: {
          cityName: string;
          usersSelectedArea: number;
        }[];
      }) => {
        const lineChartLabels = [
          "0",
          ...response[0].areaSelection.map((item) => item.cityName),
        ];
        const areaData = response[0].areaSelection.map(
          (item) => item.usersSelectedArea
        );
        this.area = areaData;
        // const notSelectedData = response[0].userNotSelected
        const dynamicChartData = [
          {
            data: [0, ...areaData],
            label: "المنطقة",
          },
          // {
          //   data: [0,notSelectedData],
          //   label: "Not Selected",
          // },
        ];

        this.dynamicChartData = dynamicChartData;
        this.lineChartLabels = lineChartLabels;
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  getUserByAreaByDay(): void {
    this.service.getUsersAreaByDay(this.date).subscribe(
      (response: {
        userNotSelected: number;
        areaSelection: {
          cityName: string;
          usersSelectedArea: number;
        }[];
      }) => {
        const areaCount = response[0]?.areaSelection.usersSelectedArea || 0;
        const notSelectedCount = response[0]?.userNotSelected || 0;
        this.count = [
          "Not selected: " + notSelectedCount,
          ...this.lineChartLabels
            .slice(1)
            .map(
              (label, index) =>
                label + ": " + this.dynamicChartData[0].data[index + 1]
            ),
        ];
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getUserByAreaByDay();
  }

  ngOnInit() {
    this.getUsersByArea();
  }
}
