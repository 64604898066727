import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
@Component({
  selector: "app-users-chart",
  templateUrl: "./users-chart.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class UsersChartComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  @Output() count: number[];
  date: any;
  chartTitleContent = "المستخدمون الجدد لكل فترة";
  constructor(private service: AnalyticsService) {}

  getNumUsers(): void {
    this.service.getNumUsers().subscribe(
      (response: { userCounts: number[] }) => {
        const { userCounts } = response;
        this.dynamicChartData = [
          {
            data: [0, ...userCounts.slice(0)],
            label: "المستخدمون",
          },
        ];
        this.lineChartLabels = ["0W", "1W", "2W", "3W", "4W"];
        console.log("user counts" + userCounts);
        console.log(this.dynamicChartData);
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  getUsersByDate(): void {
    this.service.getNumUsersByDay(this.date).subscribe(
      (res) => {
        this.count = [res];
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getUsersByDate();
  }

  ngOnInit() {
    this.getNumUsers();
  }
}
