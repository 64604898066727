import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class ParametersService {
  private apiUrl2: string = `${environment.API_URL}/api/HomeUser`;
  private apiUrl: string = `${environment.API_URL}/api/Dashboard`;
  private tokenKey = "auth_token";
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  addParameter(
    parameter_en: string,
    parameter_ar: string,
    type: any,
    values: {
      value_en: string;
      value_ar: string;
    }[]
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("parameter_en", parameter_en);
    inputs.append("parameter_ar", parameter_ar);
    inputs.append("type", type);
    inputs.append("values", JSON.stringify(values));
    return this.http
      .post<any>(
        `${this.apiUrl}/AddParameter`,
        JSON.stringify({
          parameter_en,
          parameter_ar,
          type,
          values,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getParameter(parameterId: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetParameter/?ParameterId=${parameterId}`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllParameters(lang: string = "ar"): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl2}/GetPersonalParametersWithValues?lang=${lang}`)
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  updateParameter(
    ParameterId: string,
    title_en: string,
    title_ar: string,
    parameterType: any,
    values: {
      value_en: string;
      value_ar: string;
    }[]
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("parameter_en", title_en);
    inputs.append("parameter_ar", title_ar);
    inputs.append("type", parameterType);
    inputs.append("values", JSON.stringify(values));
    return this.http
      .put<any>(
        `${this.apiUrl}/UpdateParameter/?ParameterId=${ParameterId}`,
        {
          title_en,
          title_ar,
          parameterType,
          values,
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  deleteParameter(ParameterId: string): Observable<any> {
    return this.http
      .delete<any>(
        `${this.apiUrl}/DeleteParameter/?ParameterId=${ParameterId}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(`Parameter ${ParameterId} deleted`);
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
}
