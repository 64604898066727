import { Component, Input, OnInit } from "@angular/core";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { Color, Label } from "ng2-charts";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
})
export class BarChartComponent implements OnInit {
  chartTitleContent = "";
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          position: "bottom",
          gridLines: {
            color: "#FFE9F3",
          },
          ticks: {
            fontColor: "#A12D63",
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
          gridLines: {
            color: "#FFE9F3",
          },
          ticks: {
            fontColor: "#A12D63",
            beginAtZero: true,
            stepSize: 20,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public barChartColors: Color[] = [
    {
      // main
      backgroundColor: "#A12D63",
      borderColor: "#A12D63",
      pointBackgroundColor: "#A12D63",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#A12D63",
      pointHoverBorderColor: "#A12D63",
    },
  ];
  @Input() barChartLabels: Label[]
  // public barChartLabels: Label[] = [
  //   "اليهودية",
  //   "النصرانية",
  //   "2008",
  //   "2009",
  //   "2010",
  //   "2011",
  //   "2012",
  // ];
  public barChartType: ChartType = "bar";
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];

  @Input() dynamicChartData: ChartDataSets[];
  // public barChartData: ChartDataSets[] = [
  //   {
  //     data: [65, 59, 80, 81, 56, 55, 40],
  //     label: "الديانة",
  //     yAxisID: "y-axis-0",
  //     xAxisID: "x-axis-1",
  //   },
  // ];

  constructor() {}

  ngOnInit() {}

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.random() * 100,
      56,
      Math.random() * 100,
      40,
    ];
    this.dynamicChartData[0].data = data;
  }
}
