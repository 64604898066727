import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ParametersService } from "src/app/services/parameters/parameters.service";
@Component({
  selector: "app-add-parameter",
  templateUrl: "./add-parameter.component.html",
  styleUrls: [
    "./add-parameter.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AddParameterComponent implements OnInit {
  response:any;
  title: string;
  values: { value_ar: string, value_en: string }[] = [];
  parameter_en: string;
  parameter_ar: string;
  type: number;
  selectedTypeId: number | null = null;
  selectedTypeName:any;
  types: any[] = [
    // { id: 0, name: "Select" },
    // { id: 1, name: "MultiSelect" },
    // { id: 2, name: "Text" },
    // { id: 3, name: "Date" },
    // { id: 4, name: "Number" },
    { id: 0, name: "إختيارات" },
    { id: 1, name: "إختيار متعدد" },
    { id: 2, name: "نصٌ" },
    { id: 3, name: "تاريخ" },
    { id: 4, name: "رقم" },
  ];
  constructor(
    private modalService: NgbModal,
    private service: ParametersService
  ) {}
  
  ngOnInit(): void {
    console.log(this.type);
  }

  onTypeClick(typeId: number): void {
    this.selectedTypeId = typeId;
    console.log("Selected id: ", this.selectedTypeId);
    this.updateSelectedTypeName();
  }

  updateSelectedTypeName(): void {
    const selectedType = this.types.find(
      // here the type is selected
      (selectedTypeId) => selectedTypeId.id === this.selectedTypeId
    );
    this.selectedTypeName = selectedType ? selectedType.name : null;
    console.log("olaaa",selectedType)
  }
  addDiv() {
    this.values.push({ value_ar: '', value_en: '' });
    console.log('values', this.values);
  }

  addParameter() {
    const parameterData = {
      parameter_en: this.parameter_en,
      parameter_ar: this.parameter_ar,
      type: this.selectedTypeId,
      values: this.values,
      
    };
    this.service.addParameter(
      parameterData.parameter_ar,
      parameterData.parameter_en,
      parameterData.type,
      parameterData.values
    ).subscribe(
      (data: any) => {
        this.response = data;
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnSubmit() {
    console.log("last ", this.values);
  }
}
