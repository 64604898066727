<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <h1 class="modal-title main-color title">إضافة مشرف جديد</h1>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="register()" class="p-0">
            <div class="container">
                <p class="main-color regular-20">من فضلك أدخل البريد الإلكتروني لإرسال الدعوة</p>
                <input id="name" class="form-control" placeholder="اسم" [(ngModel)]="Name" name="Name" />
                <input id="email" class="form-control" placeholder="بريد إلكتروني" [(ngModel)]="Email" name="Email" />
                <input id="password" class="form-control" placeholder="كلمة السر" [(ngModel)]="Password"
                    name="Password" />
                <input id="confirmPassword" class="form-control" placeholder="تأكيد كلمة السر"
                    [(ngModel)]="ConfirmPassword" name="ConfirmPassword" />
                <input id="phoneNumber" class="form-control" placeholder="رقم الهاتف المحمول" [(ngModel)]="PhoneNumber"
                    name="PhoneNumber" />
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn button-large">إضافة</button>
            </div>
        </form>
    </div>
</ng-template>
<button (click)="openmodal(content)" class="border-0 btn">
    <a class="me-1 main-color" style="font-size: 20px ;"><ins>إضافة مشرف</ins></a>
    <span class="material-icons main-color mx-2" style="font-size: 20px ;">+</span>
</button>