import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-register-boxed",
  templateUrl: "./register-boxed.component.html",
  styles: [],
})
export class RegisterBoxedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
