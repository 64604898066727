import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

@Component({
  selector: "app-users-by-gender-chart",
  templateUrl: "./users-by-gender-chart.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class UsersByGenderChartComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  @Output() count: any[] = [];
  date: any;
  chartTitleContent = "المستخدمون حسب الجنس";
  constructor(private service: AnalyticsService) {}

  getUsersByGender(): void {
    this.service.getUsersGender().subscribe(
      (
        response: {
          week: string;
          usersMale: number;
          usersFemale: number;
          userNotSelected: number;
        }[]
      ) => {
        const lineChartLabels = ["0W", "1W", "2W", "3W", "4W"];

        const dynamicChartData = [
          {
            data: [0, ...response.map((item) => item.usersMale)],
            label: "رجال",
          },
          {
            data: [0, ...response.map((item) => item.usersFemale)],
            label: "سيدات",
          },
          {
            data: [0, ...response.map((item) => item.userNotSelected)],
            label: "غير محدد",
          },
        ];

        this.dynamicChartData = dynamicChartData;
        this.lineChartLabels = lineChartLabels;
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  getUserByGenderByDay(): void {
    this.service.getUsersGenderByDay(this.date).subscribe(
      (
        response: {
          usersMale: number;
          usersFemale: number;
          userNotSelected: number;
        }[]
      ) => {
        const maleCount = response[0]?.usersMale || 0;
        const femaleCount = response[0]?.usersFemale || 0;
        const notSelectedCount = response[0]?.userNotSelected || 0;

        this.count = [
          "رجال : " + maleCount,
          "سيدات : " + femaleCount,
          "غير محدد : " + notSelectedCount,
        ];
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getUserByGenderByDay();
  }

  ngOnInit() {
    this.getUsersByGender();
  }
}
