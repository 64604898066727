<div class="users-table">
    <div class="table-responsive mb-5">
        <table class="align-middle table-responsive text-truncate mb-0 table">
            <thead class="regular-18 main-color">
                <tr class="ta">
                    <th class="text-center" (click)="sortUsers()" style="cursor: pointer;">اسم</th>
                    <th class="text-center">بريد إلكتروني</th>
                    <th class="text-center">
                        <div ngbDropdown>
                            <button type="button" style="border: none;" ngbDropdownToggle
                                class="btn menu-button"><span>دور</span></button>
                            <div ngbDropdownMenu>
                                <button class="dropdown-item" (click)="filterByRole('Supervisor')">مشرف</button>
                                <button class="dropdown-item" (click)="filterByRole('Admin')">مسؤول</button>
                            </div>
                        </div>
                    </th>
                    <th class="text-center"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mod of filteredUsers ; index as i">
                    <td class="text-center">{{mod.name}}</td>
                    <td class="text-center">
                        {{mod.email}}
                    </td>
                    <td>{{mod.roles[0] === 'Admin' ? 'مسؤول': 'مشرف'}}</td>
                    <td class="text-center">
                        <div class="btn-group-sm btn-group d-flex justify-content-end" style="gap: 30px;">
                            <app-edit-moderator [name]="mod.name" [email]="mod.email"
                                [moderatorId]="mod.id"></app-edit-moderator>
                            <app-delete-moderator [AdminId]="mod.id"></app-delete-moderator>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-between table-footer">
        <div class="d-flex col-sm-6 justify-content-start gap-5 pagination-container">
            <ngb-pagination [(collectionSize)]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refresh()">
            </ngb-pagination>
            <select class="select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refresh()">
                <option [ngValue]="5">5/صفحة </option>
                <option [ngValue]="10">10/صفحة</option>
                <option [ngValue]="15">15/صفحة</option>
            </select>
        </div>
        <!-- <div class="d-flex col-md-6 col-sm-6 gap-3">
            <button class="btn message-btn btn-outline">إرسال رسالة عبر النشرة
                الإخبارية</button>
            <button class="btn message-btn button-large">إرسال إشعار للمستخدمين</button>
        </div> -->
    </div>
</div>