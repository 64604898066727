import { Component, OnInit } from "@angular/core";
import { ThemeOptions } from "../../../../../theme-options";
import { AuthenticationService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
  styleUrls: ["./user-box.component.scss"],
})
export class UserBoxComponent implements OnInit {
  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  logout(): void {
    this.authService.logout();
    this.router.navigate(["/pages/login"]);
    console.log("first");
  }

  ngOnInit() {}
}
