import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
@Component({
  selector: "app-public-activities",
  templateUrl: "./public-activities.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class PublicActivitiesComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  @Output() count: number[] = [];
  date: any;
  chartTitleContent = "النشاط الشعبي في النظام";
  constructor(private service: AnalyticsService) {}

  getPublicActivities(): void {
    this.service.getPublicActivities().subscribe(
      (response: { percentages: number[] }) => {
        const { percentages } = response;
        this.dynamicChartData = [
          {
            data: [0, ...percentages.slice(0)],
            label: "النشاط الشعبي",
          },
        ];
        this.lineChartLabels = ["0W", "1W", "2W", "3W", "4W"];
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  getPublicActivitiesByDate(): void {
    this.service.getPublicActivitiesByDay(this.date).subscribe(
      (response: { percentages: number }) => {
        this.count = [response.percentages];
        console.log(response.percentages);
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getPublicActivitiesByDate();
  }

  ngOnInit() {
    this.getPublicActivities();
  }
}
