<div class="users-table">
  <div class="table-responsive mb-5">
    <table class="align-middle table-responsive text-truncate mb-0 table">
      <thead class="regular-18 main-color">
        <tr class="ta">
          <th class="text-center" (click)="sortUsers()" style="cursor: pointer">
            اسم
          </th>
          <th class="text-center">بريد إلكتروني</th>
          <th class="text-center">التقييم</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of filteredUsers; index as i">
          <td>
            <a class="main-color" [routerLink]="['/user-profile', user.id]">
              {{ user.userName }}
            </a>
          </td>
          <td class="text-center">
            {{ user.userEmail }}
          </td>
          <td class="text-center">
            {{ user.message }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between table-footer">
    <div
      class="d-flex col-sm-6 justify-content-start gap-5 pagination-container"
    >
      <ngb-pagination
        [(collectionSize)]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refresh()"
      >
      </ngb-pagination>
      <select
        class="select"
        style="width: auto"
        [(ngModel)]="pageSize"
        (ngModelChange)="refresh()"
      >
        <option [ngValue]="5">5/صفحة</option>
        <option [ngValue]="10">10/صفحة</option>
        <option [ngValue]="15">15/صفحة</option>
      </select>
    </div>
  </div>
</div>
