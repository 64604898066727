<ng-template #content let-modal class="rounded-2">
  <div class="modal-header">
    <h1 class="modal-title main-color title">تحرير البيانات الشخصية</h1>
    <div>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
  </div>
  <div class="modal-body">
    <form class="p-0" (ngSubmit)="updateUser()">
      <div class="mb-3">
        <div class="container">
          <input class="form-control" [(ngModel)]="Name" name="Name" />
          <div class="d-flex justify-content-between">
            <div style="width: 48%">
              <label for="gender" class="main-color mb-2">الجنس</label>
              <div ngbDropdown>
                <button
                  type="button"
                  name="gender"
                  ngbDropdownToggle
                  class="form-control"
                >
                  <span class="regular-16">{{ selectedGenderName }}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    name="Gende"
                    *ngFor="let gender of genders"
                    (click)="onValueClick(gender.id)"
                    class="dropdown-item"
                  >
                    {{ gender.name }}
                  </button>
                </div>
              </div>
            </div>
            <div style="width: 48%">
              <label for="smoke" class="main-color mb-2">التدخين</label>
              <div ngbDropdown>
                <button
                  type="button"
                  name="smoke"
                  ngbDropdownToggle
                  class="form-control"
                >
                  <span
                    *ngIf="selectedSmokingPreference === true"
                    class="regular-16"
                    >يدخن</span
                  >
                  <span
                    *ngIf="selectedSmokingPreference === false"
                    class="regular-16"
                    >لا يدخن</span
                  >
                </button>
                <div ngbDropdownMenu>
                  <button
                    (click)="onSmokingPreferenceClick(true)"
                    class="dropdown-item"
                  >
                    يدخن
                  </button>
                  <button
                    (click)="onSmokingPreferenceClick(false)"
                    class="dropdown-item"
                  >
                    لا يدخن
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div style="width: 48%">
              <label for="Height" class="main-color mb-2">الطول</label>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="Height"
                name="Height"
              />
            </div>
            <div style="width: 48%">
              <label for="Height" class="main-color mb-2">الوزن</label>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="Weight"
                name="Weight"
              />
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <div style="width: 48%">
              <label for="city" class="main-color mb-2">المدينة</label>
              <div ngbDropdown>
                <button
                  type="button"
                  name="city"
                  ngbDropdownToggle
                  class="form-control"
                >
                  <!-- not working yet  -->
                  <span class="regular-16">{{ selectedCity }}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    name="Gende"
                    *ngFor="let city of allCities"
                    (click)="onCityClick(city.id)"
                    class="dropdown-item"
                  >
                    {{ city.name }}
                  </button>
                </div>
              </div>
            </div>

            <div style="width: 48%">
              <label for="Height" class="main-color mb-2">المنطقة</label>
              <div ngbDropdown>
                <button
                  type="button"
                  name="area"
                  ngbDropdownToggle
                  class="form-control"
                >
                  <span class="regular-16">{{ Area }}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    name="Gende"
                    *ngFor="let gender of genders"
                    (click)="onValueClick(gender.id)"
                    class="dropdown-item"
                  >
                    {{ gender.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex justify-content-between">
            <div style="width: 48%">
              <button>إضافة صفة</button>
              </div>
            <div style="width: 48%">
              <div ngbDropdown>
                <button
                  type="button"
                  name="area"
                  ngbDropdownToggle
                  class="form-control"
                >
                  <span class="regular-16">{{ parameter name }}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    name="Gende"
                    *ngFor="let gender of genders"
                    (click)="onValueClick(gender.id)"
                    class="dropdown-item"
                  >
                    {{ gender.name }}
                  </button>
                </div>
              </div>
            </div>
          </div> -->

          
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn button-large">حفظ</button>
      </div>
    </form>
  </div>
</ng-template>

<button (click)="openmodal(content)" class="border-0 btn p-0">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20H21"
      stroke="#B9B9B9"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
      stroke="#B9B9B9"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>
