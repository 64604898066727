<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Basic
      </div>
      <div class="card-body">
        <span *ngIf="closeResult" class="kt-section__sub">
          <pre>{{closeResult}}</pre>
        </span>
        <div class="divider"></div>
        <ng-template #content let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">Basic demo</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
            </button>
          </div>
          <div class="modal-body">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining
              essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
              passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </ng-template>
        <button class="btn btn-primary" (click)="open(content)">Launch demo modal</button>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Scrollable Fixed Content
      </div>
      <div class="card-body">
        <ng-template #content let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">Scrollable fixed content</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">>
            </button>
          </div>
          <div class="modal-body">
            <perfect-scrollbar style="height: 250px;" [config]="config">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </perfect-scrollbar>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </ng-template>
        <button class="btn btn-primary" (click)="open(content)">Launch demo modal</button>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Long Content
      </div>
      <div class="card-body">
        <ng-template #content5 let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">Scrollable fixed content</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
            </button>
          </div>
          <div class="modal-body">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum ateros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
              leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
              leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
              leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum. Cras justo
              odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
              leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum. Cras justo
              odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi
              leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum. Cras justo
              odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </ng-template>
        <button class="btn btn-primary" (click)="open(content5)">Launch demo modal</button>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Large Modal
      </div>
      <div class="card-body">
        <ng-template #content6 let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">Modal title</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
            </button>
          </div>
          <div class="modal-body">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </ng-template>
        <button class="btn btn-success" (click)="openLarge(content6)">Launch demo modal</button>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Small Modal
      </div>
      <div class="card-body">
        <ng-template #content7 let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">Modal title</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
            </button>
          </div>
          <div class="modal-body">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </ng-template>
        <button class="btn btn-success" (click)="openSmall(content7)">Launch demo modal</button>
      </div>
    </div>
  </div>
</div>
