import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: [
    "./search.component.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/buttons.scss",
  ],
})
export class SearchComponent {
  @Output() searchTextChanged = new EventEmitter<string>();
  searchText: any = "";

  onSearchTextChange(e) {
    this.searchTextChanged.emit(e);
  }
}
