<div class="analytics">
  <div class="justify-content-between head d-flex mb-2">
    <div>
      <h1 class="title title-large main-color">التحليلات</h1>
    </div>
    <div>
      <app-search></app-search>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class=" mb-3">
          <app-users-chart></app-users-chart>
          <app-public-activities></app-public-activities>
          <app-users-by-gender-chart></app-users-by-gender-chart>
          <app-users-by-area-chart></app-users-by-area-chart>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <app-users-by-marital-status-chart></app-users-by-marital-status-chart>
          <div class="text-center mt-5 main-color">
            <h3>الصفات</h3>
          </div>
          <app-parameters-chart></app-parameters-chart>

        </div>
      </div>
    </div>
  </div>
</div>