<!-- <app-chart-header [chartTitle]="chartTitleContent" [count]="count" (dateSelected)="onDateSelected($event)">
    <p class="regular-18 mb-0 main-color">{{ chartTitleContent }}</p>
</app-chart-header> -->


<div ngbDropdown>
    <button type="button" ngbDropdownToggle class="mb-2 me-2 btn menu-button "><span class="regular-16">إختر
            الصفة</span></button>
    <div ngbDropdownMenu>
        <button *ngFor="let para of parameters" (click)="onButtonClick(para.parameterId)"
            class="dropdown-item">{{para.title}}</button>
    </div>
</div>


<div *ngFor="let para of parameters">
    <div *ngIf="selectedPara === para.parameterId" ngbDropdown>
        <app-chart-header [chartTitle]="chartTitleContent" [count]="count" (dateSelected)="onDateSelected($event)">
            <p class="regular-18 mb-0 main-color">{{ chartTitleContent }}</p>
        </app-chart-header>
    </div>
</div>


<div *ngFor="let para of parameters">
    <div *ngIf="selectedPara === para.parameterId" ngbDropdown>
        <app-bar-chart [dynamicChartData]="dynamicChartData" [barChartLabels]="barChartLabels"></app-bar-chart>
    </div>
</div>