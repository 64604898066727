<ng-template #content let-modal class="rounded-2">
  <div class="modal-header">
    <h1 class="modal-title main-color title">إرسال رسالة</h1>
    <div>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="blockUser()" class="p-0">
      <div class="modal-footer">
        <textarea
          class="form-control"
          name="message"
          id="message"
          cols="30"
          rows="5"
          style="height: 100%"
        ></textarea>
        <button type="submit" class="btn button-large">إرسال</button>
        <button
          type="button"
          (click)="modal.dismiss('Cross click')"
          class="btn btn-outline main-color"
        >
          إلغاء
        </button>
      </div>
    </form>
  </div>
</ng-template>
<button
  (click)="openModal(content)"
  class="btn btn-outline"
  style="
    border: 0px !important;
    width: max-content !important;
    padding: 0 !important;
  "
>
  إرسال رسالة
</button>
