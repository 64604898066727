import { Component, Input, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { ParametersService } from "src/app/services/parameters/parameters.service";

@Component({
  selector: "app-parameters-chart",
  templateUrl: "./parameters-chart.component.html",
  styleUrls: ["../../../../../layout/styles/modal.scss"],
})
export class ParametersChartComponent implements OnInit {
  dynamicChartData: ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  @Input() count: any[];
  lang:any;
  date: any;
  chartTitleContent = "";
  @Input() Ids: string;
  ola: string[];
  week: number = 2;
  parameters: any;
  selectedPara:any;
  constructor(
    private service: AnalyticsService,
    private paraService: ParametersService
  ) {}

  onButtonClick(parameterId: number): void {
    this.selectedPara = parameterId;
    console.log("para clicked", this.selectedPara)
    this.getParameters();
  }

  getParameters(): void {
    this.service.getParameterById(this.selectedPara , this.lang).subscribe(
      (response: {
        parameterData: {
          parameterName: string;
          userCountSelectedParameter: number;
          notSelected: number;
          values: {
            valueId: null;
            userCount: number;
            valueName: string;
          }[];
        };
      }) => {
        const data = response.parameterData;
        const parameterName = data.parameterName;
        const userCount = data.values.map((item) => item.userCount);
        const names = data.values.map((item) => item.valueName);
        this.dynamicChartData = [
          {
            data: userCount,
          },
        ];
        this.barChartLabels = names;
        this.chartTitleContent = parameterName;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getParametersByDay(): void {
    this.service.getParameterByIdByDay(this.selectedPara, this.date).subscribe(
      () => {
        this.count = [
          ...this.barChartLabels
            .slice()
            .map(
              (label, index) =>
                label + ": " + this.dynamicChartData[0].data[index + 1]
            ),
        ];
      },
      (error) => {
        console.log("An error occurred: " + error);
      }
    );
  }

  onDateSelected(date: NgbDateStruct) {
    this.date = date;
    this.getParametersByDay();
  }

  ngOnInit() {
    // this.getParameters();

    this.paraService.getAllParameters().subscribe(
      (parameters) => {
        this.parameters = parameters;
        console.log(this.parameters);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
