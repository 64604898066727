import { Component, OnInit } from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: [
    "./users.component.scss",
    "../../layout/styles/fonts.scss",
    "../../layout/styles/buttons.scss",
  ],
})
export class UsersComponent implements OnInit {
  searchText: any = "";
  users: any;
  page = 1;
  pagesLimit = 10;
  pageSize = 4;
  collectionSize = 0;
  filteredUsers: any;
  parameterId: any | "";
  valueId: any | "";
  lang: string = "ar";
  selectedParameterId: number | "";
  selectedValueId: number | "";

  constructor(private service: UsersService) {}

  onParameterClick(parameterId: number): void {
    this.selectedParameterId = parameterId;
  }

  onValueClick(valueId: number): void {
    this.selectedValueId = valueId;
  }

  refresh() {
    this.users
      .map((user, i) => ({ id: i + 1, ...user }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  filterUsers() {
    if (this.searchText.trim().length == 0) {
      this.filteredUsers = this.users;
    } else {
      let original = this.users;
      this.filteredUsers = original.filter((user) => {
        const term = this.searchText.toLowerCase();
        return Object.values(user).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(term);
          }
          if (typeof value === "number") {
            const stringValue = value.toString();
            return stringValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
  }

  getAllUsers() {
    this.service
      .getAllUsersFiltered(
        this.page,
        this.pagesLimit,
        this.parameterId,
        this.valueId,
        this.lang
      )
      .subscribe(
        (users) => {
          this.users = users;
          this.collectionSize = users.length;
        },
        (error) => {
          console.error("An error occurred:", error);
        }
      );
  }

  ngOnInit() {
    this.getAllUsers();
  }
  ngOnChanges() {
    this.filterUsers();
  }
}
