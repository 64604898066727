import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modals",
  templateUrl: "./modals.component.html",
})
export class ModalsComponent implements OnInit {
  heading = "Modals";
  subheading =
    "Wide selection of modal dialogs styles and animations available.";
  icon = "pe-7s-phone icon-gradient bg-premium-dark";

  closeResult: string;

  constructor(private modalService: NgbModal) {}

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openCentred(content) {
    this.modalService.open(content, { centered: true });
  }

  openSmall(content) {
    this.modalService.open(content, {
      size: "sm",
    });
  }

  openLarge(content) {
    this.modalService.open(content, {
      size: "lg",
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {}
}
