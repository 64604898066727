import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
} from "@angular/core";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateRef } from "@angular/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = "-";

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

@Component({
  selector: "app-all-data",
  templateUrl: "./all-data.component.html",
  styleUrls: [
    "../../../../../../layout/styles/modal.scss",
    "../../../../../../layout/styles/buttons.scss",
    "../../../../../../layout/styles/fonts.scss",
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
  ],
})
export class AllDataComponent {
  @Input() chartTitle: string = "";
  @Input() count: any[] ;
  model: any;
  @Output() dateSelected: EventEmitter<any> =
  new EventEmitter<any>();
 
  constructor(private modalService: NgbModal) {}

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  onDateSelected() {
    this.dateSelected.emit(this.model);
    console.log("model is " + this.model);
  }

  onDateSelrected() {
    // Split the date string into day, month, and year
    const [day, month, year] = this.model.split('-');
  
    // Create a new Date object in the "mm/dd/yyyy" format
    const dateInMMDDYYYYFormat = new Date(`${month}/${day}/${year}`);
  
    // Emit the formatted date
    this.dateSelected.emit(dateInMMDDYYYYFormat);
    console.log("Formatted date is " + dateInMMDDYYYYFormat);
  }
  
}
