import { Component, TemplateRef, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/services/auth/auth.service";
@Component({
  selector: "app-add-admin",
  templateUrl: "./add-admin.component.html",
  styleUrls: [
    "./add-admin.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AddAdminComponent {
  UserId: string;
  role: string = "Admin";
  Name: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
  PhoneNumber: string;
  error: string;

  constructor(
    private modalService: NgbModal,
    private authServise: AuthenticationService
  ) {}

  register(): void {
    this.authServise
      .addAdmin(
        this.role,
        this.Name,
        this.Email,
        this.Password,
        this.PhoneNumber,
        this.ConfirmPassword
      )
      .subscribe(
        (response) => {
          this.UserId = response.id;
          console.log("Registration successful");
          console.log("this is the user id" + this.UserId);
          console.log(response);
          this.modalService.dismissAll();
          const token = response.token;
          this.authServise.storeToken(token);
          location.reload();

          // this.setAdmin();
        },
        (error) => {
          this.error =
            error?.message || "An error occurred during adding new user.";
          console.log(error);
        }
      );
  }

  // setAdmin() {
  //   this.authServise
  //     .setUserRole(this.UserId, this.RoleName)
  //     .subscribe((response) => {
  //       this.RoleName = response.RoleName;
  //       this.RoleName === "Admin",
  //         console.log("role is set to admin successfully");
  //     });
  // }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }
}
