import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PaymentService } from "src/app/services/payment/payment.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-payment-table",
  templateUrl: "./payment-table.component.html",
  styleUrls: [
    "./payment-table.component.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/table.scss",
  ],
})
export class PaymentTableComponent implements OnInit {
  pagesLimit = 10;
  payments: any[];
  UserId: string;
  page = 1;
  size = 4;
  pageSize = 5;
  @Output() collectionSize = new EventEmitter<number>();
  filter = new FormControl("");
  MonthsNum: number = undefined;
  filteredUsers: any[] = [];
  @Input() searchText: any = "";
  undefinedValue: any;
  constructor(private service: PaymentService) {}

  filterUsers() {
    const term = this.searchText.toLowerCase().trim();
    if (term === "") {
      this.filteredUsers = this.payments;
      location.reload();
    } else {
      this.filteredUsers = this.payments.filter((user) => {
        const term = this.searchText.toLowerCase();
        return Object.values(user).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(term);
          }
          if (typeof value === "number") {
            const stringValue = value.toString();
            return stringValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
  }

  setMonths(months: number) {
    this.MonthsNum = months;
    this.getAllSubscriptions();
  }
  getAllSubscriptions(): void {
    this.service
      .getAllSubscriptions(this.page, this.pagesLimit, this.MonthsNum)
      .subscribe(
        (subscriptions) => {
          this.payments = subscriptions;
          this.filteredUsers = subscriptions;
          this.collectionSize.emit(subscriptions.length);
          console.log(subscriptions);
        },
        (error) => {
          console.log("An error occurred: " + error);
        }
      );
  }

  refresh() {
    this.payments
      .map((payment, i) => ({ id: i + 1, ...payment }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }
  isAscending = true;

  sortUsers() {
    this.payments.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      const compareResult = nameA.localeCompare(nameB);
      return this.isAscending ? compareResult : -compareResult; // Use the flag from the outer scope
    });
    this.isAscending = !this.isAscending; // Toggle the flag for the next click
  }

  ngOnInit(): void {
    this.getAllSubscriptions();
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredUsers = this.payments;
    } else {
      this.filterUsers();
    }
  }
}
