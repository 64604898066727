import { Component, Input, OnInit, Output } from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: [
    "./user-profile.component.scss",
    "../../layout/styles/buttons.scss",
    "../../layout/styles/fonts.scss",
    "../../layout/styles/modal.scss",
  ],
})
export class UserProfileComponent implements OnInit {
  UseId: string;
  user: any;
  images: string[];
  parameters: [key: string, value: string];
  path: string = "https://zawaj.somee.com/images/";
  liked: any[];
  whoLiked: any[];
  isBlocked: boolean;
  showAllDivs = false;
  constructor(private service: UsersService, private route: ActivatedRoute) {}

  getUserById() {
    this.service.getUserById(this.UseId).subscribe(
      (res: any) => {
        this.user = res;
        this.images = res.images;
        this.parameters = res.parameters;
        this.isBlocked = res.isBlocked;
        console.log("this is the user ", res);
      },
      (error) => {
        error = error?.message || "An error occurred during adding new user.";
        console.log(error);
      }
    );
  }
  getLikedUsers() {
    this.service.getLikedUsersById(this.UseId).subscribe(
      (res) => {
        this.liked = res;
        // console.log("this is the liked users ", res);
      },
      (error) => {
        error = error?.message || "An error occurred during adding new user.";
        console.log(error);
      }
    );
  }

  getWhoLikedUser() {
    this.service.getWhoLikedUserById(this.UseId).subscribe(
      (res) => {
        this.whoLiked = res;
        // console.log("this is the liked users ", res);
      },
      (error) => {
        error = error?.message || "An error occurred during adding new user.";
        console.log(error);
      }
    );
  }

  toggleShowAllDivs(): void {
    this.showAllDivs = !this.showAllDivs;
  }
  ngOnInit() {
    // Access route parameter (user.id)
    this.route.params.subscribe((params) => {
      this.UseId = params["id"]; // '+' is used to convert the parameter to a number
    });
    this.getUserById();
    this.getLikedUsers();
    this.getWhoLikedUser();
    console.log("User ID in User Detail Component:", this.UseId);
  }
}
