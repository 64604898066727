<div class="h-100 text-center">
    <div class="mt-5">
        <img src="../../../../assets/images/Logo.png" alt="zawaj">
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="dialog mt-5 mx-auto col-md-8">
            <h1 class="title-large">كلمة المرور الجديدة</h1>
            <p class="sub-title mb-4" style="margin-top: 12px;">
                أدخل كلمة المرور الجديدة في حقل الإدخال أدناه.</p>
            <form (ngSubmit)="resetPassword()">
                <div role="group" class="mb-3">
                    <div>
                        <label for="password" class="label-16 mb-2">كلمة المرور</label>
                        <input name="password" type="password" placeholder="اكتب كلمة المرور الخاصة بك هنا"
                            [(ngModel)]="password" class="form-control" required>
                    </div>
                    <div>
                        <label for="password" class="label-16 mt-2 mb-2">تأكيد كلمة المرور</label>
                        <input name="password" type="password" placeholder="اكتب كلمة المرور الخاصة بك هنا"
                            [(ngModel)]="confirmPassword" class="form-control" required>
                    </div>
                    <!-- <div class="error mt-3 mb-1">
                  <div>عذرًا، البريد الإلكتروني الخاص بك غير صحيح يرجى التأكد منه
                  </div>
                </div> -->
                    <button type="submit" class="btn btn-lg mt-4 button-large">تسجيل الدخول
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>