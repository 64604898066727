import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgReduxModule } from "@angular-redux/store";
import { NgRedux, DevToolsExtension } from "@angular-redux/store";
import { rootReducer, ArchitectUIState } from "./ThemeOptions/store";
import { ConfigActions } from "./ThemeOptions/store/config.actions";
import { AppRoutingModule } from "./app-routing.module";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { CommonModule, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";

// BOOTSTRAP COMPONENTS

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ChartsModule } from "ng2-charts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

// LAYOUT

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";
import { PageTitleComponent } from "./Layout/Components/page-title/page-title.component";

// HEADER

import { HeaderComponent } from "./Layout/Components/header/header.component";
import { SearchBoxComponent } from "./Layout/Components/header/elements/search-box/search-box.component";
import { UserBoxComponent } from "./Layout/Components/header/elements/user-box/user-box.component";

// SIDEBAR

import { SidebarComponent } from "./Layout/Components/sidebar/sidebar.component";
import { LogoComponent } from "./Layout/Components/sidebar/elements/logo/logo.component";

// FOOTER

import { FooterComponent } from "./Layout/Components/footer/footer.component";

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from "./DemoPages/Dashboards/analytics/analytics.component";

// Pages

import { ForgotPasswordBoxedComponent } from "./DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component";
import { LoginBoxedComponent } from "./DemoPages/UserPages/login-boxed/login-boxed.component";
import { RegisterBoxedComponent } from "./DemoPages/UserPages/register-boxed/register-boxed.component";

// Elements

import { StandardComponent } from "./DemoPages/Elements/Buttons/standard/standard.component";
import { DropdownsComponent } from "./DemoPages/Elements/dropdowns/dropdowns.component";
import { CardsComponent } from "./DemoPages/Elements/cards/cards.component";
import { ListGroupsComponent } from "./DemoPages/Elements/list-groups/list-groups.component";
import { TimelineComponent } from "./DemoPages/Elements/timeline/timeline.component";
import { IconsComponent } from "./DemoPages/Elements/icons/icons.component";

// Components

import { AccordionsComponent } from "./DemoPages/Components/accordions/accordions.component";
import { TabsComponent } from "./DemoPages/Components/tabs/tabs.component";
import { CarouselComponent } from "./DemoPages/Components/carousel/carousel.component";
import { ModalsComponent } from "./DemoPages/Components/modals/modals.component";
import { ProgressBarComponent } from "./DemoPages/Components/progress-bar/progress-bar.component";
import { PaginationComponent } from "./DemoPages/Components/pagination/pagination.component";
import { TooltipsPopoversComponent } from "./DemoPages/Components/tooltips-popovers/tooltips-popovers.component";

// Tables

import { RegularComponent } from "./DemoPages/Tables/regular/regular.component";
import { TablesMainComponent } from "./DemoPages/Tables/tables-main/tables-main.component";

// Widgets

import { ChartBoxes3Component } from "./DemoPages/Widgets/chart-boxes3/chart-boxes3.component";

// Forms Elements

import { ControlsComponent } from "./DemoPages/Forms/Elements/controls/controls.component";
import { LayoutComponent } from "./DemoPages/Forms/Elements/layout/layout.component";

// Charts

import { ChartjsComponent } from "./DemoPages/Charts/chartjs/chartjs.component";

// Chart.js Examples

import { LineChartComponent } from "./DemoPages/Charts/chartjs/examples/line-chart/line-chart.component";
import { BarChartComponent } from "./DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component";
import { ResetPasswordComponent } from "./DemoPages/UserPages/reset-password/reset-password.component";
import { UsersComponent } from "./DemoPages/users/users.component";
import { ParametersComponent } from "./DemoPages/users/parameters/parameters.component";
import { UsersTableComponent } from "./DemoPages/users/users-table/users-table.component";
import { EditUserComponent } from "./DemoPages/users/edit-user/edit-user.component";
import { DeleteUserComponent } from "./DemoPages/users/delete-user/delete-user.component";
import { AddAdminComponent } from "./DemoPages/moderators/add-admin/add-admin.component";
import { PersonalParametersComponent } from "./DemoPages/personal-parameters/personal-parameters.component";
import { ParametersTableComponent } from "./DemoPages/personal-parameters/parameters-table/parameters-table.component";
import { AddParameterComponent } from "./DemoPages/personal-parameters/add-parameter/add-parameter.component";
import { EditParameterComponent } from "./DemoPages/personal-parameters/edit-parameter/edit-parameter.component";
import { DeleteParameterComponent } from "./DemoPages/personal-parameters/delete-parameter/delete-parameter.component";
import { ModeratorsComponent } from "./DemoPages/moderators/moderators.component";
import { PaymentComponent } from "./DemoPages/payment/payment.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SearchComponent } from "./DemoPages/users/search/search.component";
import { ModeratorsTableComponent } from "./DemoPages/moderators/moderators-table/moderators-table.component";
import { PaymentTableComponent } from "./DemoPages/payment/payment-table/payment-table.component";
import { ChartHeaderComponent } from "./DemoPages/Charts/chartjs/examples/chart-header/chart-header.component";
import { AddModeratorComponent } from "./DemoPages/users/add-moderator/add-moderator.component";
import { EditModeratorComponent } from "./DemoPages/moderators/edit-moderator/edit-moderator.component";
import { DeleteModeratorComponent } from "./DemoPages/moderators/delete-moderator/delete-moderator.component";
import { UnauthorizedComponent } from "./DemoPages/unauthorized/unauthorized.component";
import { UsersChartComponent } from "./DemoPages/Charts/chartjs/examples/users-chart/users-chart.component";
import { AllDataComponent } from "./DemoPages/Charts/chartjs/examples/chart-header/all-data/all-data.component";
import { PublicActivitiesComponent } from './DemoPages/Charts/chartjs/examples/public-activities/public-activities.component';
import { UsersByGenderChartComponent } from './DemoPages/Charts/chartjs/examples/users-by-gender-chart/users-by-gender-chart.component';
import { UsersByAreaChartComponent } from './DemoPages/Charts/chartjs/examples/users-by-area-chart/users-by-area-chart.component';
import { ParametersChartComponent } from './DemoPages/Charts/chartjs/examples/parameters-chart/parameters-chart.component';
import { UsersByMaritalStatusChartComponent } from './DemoPages/Charts/chartjs/examples/users-by-marital-status-chart/users-by-marital-status-chart.component';
import { UserProfileComponent } from './DemoPages/user-profile/user-profile.component';
import { BlockUserComponent } from './DemoPages/user-profile/block-user/block-user.component';
import { BlockedUsersComponent } from './DemoPages/blocked-users/blocked-users.component';
import { BlockedUsersTableComponent } from './DemoPages/blocked-users/blocked-users-table/blocked-users-table.component';
import { SendMessageComponent } from './DemoPages/user-profile/send-message/send-message.component';
import { EditUserProfileParametersComponent } from './DemoPages/user-profile/edit-user-profile-parameters/edit-user-profile-parameters.component';
import { FeedbackComponent } from './DemoPages/feedback/feedback.component';
import { VerificationComponent } from './DemoPages/verification/verification.component';
import { FeedbackTableComponent } from './DemoPages/feedback/feedback-table/feedback-table.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    ResetPasswordComponent,
    UsersComponent,
    ParametersComponent,
    UsersTableComponent,
    EditUserComponent,
    DeleteUserComponent,
    AddAdminComponent,
    PersonalParametersComponent,
    ParametersTableComponent,
    AddParameterComponent,
    EditParameterComponent,
    DeleteParameterComponent,
    ModeratorsComponent,
    PaymentComponent,
    SearchComponent,
    ModeratorsTableComponent,
    PaymentTableComponent,
    ChartHeaderComponent,
    AddModeratorComponent,
    EditModeratorComponent,
    DeleteModeratorComponent,
    UnauthorizedComponent,
    UsersChartComponent,
    AllDataComponent,
    PublicActivitiesComponent,
    UsersByGenderChartComponent,
    UsersByAreaChartComponent,
    ParametersChartComponent,
    UsersByMaritalStatusChartComponent,
    UserProfileComponent,
    BlockUserComponent,
    BlockedUsersComponent,
    BlockedUsersTableComponent,
    SendMessageComponent,
    EditUserProfileParametersComponent,
    FeedbackComponent,
    VerificationComponent,
    FeedbackTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

    ChartsModule,
    Ng2SearchPipeModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : (f) => f]
    );
  }
}
