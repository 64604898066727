<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Grid Rows</h5>
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleEmail11" class="form-label">Email</label>
            <input name="email" id="exampleEmail11" placeholder="with a placeholder" type="email" class="form-control">
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="examplePassword11" class="form-label">Password</label>
            <input name="password" id="examplePassword11" placeholder="password placeholder" type="password" class="form-control"></div>
        </div>
      </div>
      <div class="position-relative mb-3">
        <label for="exampleAddress" class="form-label">Address</label>
        <input name="address" id="exampleAddress" placeholder="1234 Main St" type="text" class="form-control">
      </div>
      <div class="position-relative mb-3">
        <label for="exampleAddress2" class="form-label">Address 2</label>
        <input name="address2" id="exampleAddress2" placeholder="Apartment, studio, or floor" type="text" class="form-control">
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleCity" class="form-label">City</label>
            <input name="city" id="exampleCity" type="text" class="form-control">
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-relative mb-3">
            <label for="exampleState" class="form-label">State</label>
            <input name="state" id="exampleState" type="text" class="form-control">
          </div>
        </div>
        <div class="col-md-2">
          <div class="position-relative mb-3">
            <label for="exampleZip" class="form-label">Zip</label>
            <input name="zip" id="exampleZip" type="text" class="form-control">
          </div>
        </div>
      </div>
      <div class="position-relative form-check">
          <input name="check" id="exampleCheck" type="checkbox" class="form-check-input">
          <label for="exampleCheck" class="form-label form-check-label">Check me out</label>
      </div>
      <button class="mt-2 btn btn-primary">Sign in</button>
    </form>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <h5 class="card-title">Inline</h5>
    <div>
      <form class="mt -3">
          <div class="position-relative form-check form-check-inline">
               <input type="checkbox" class="form-check-input"> Some input
              <label class="form-label form-check-label"></label>
          </div>
          <div class="position-relative form-check form-check-inline">
              <input type="checkbox" class="form-check-input"> Some other input
              <label class="form-label form-check-label"></label>
          </div>
      </form>
      <div class="divider"></div>
      <form class="row g-3">
          <div class="col-auto">
              <label for="staticEmail2" class="visually-hidden">Email</label>
              <input type="text" class="form-control" id="staticEmail2" value="email@example.com">
          </div>
          <div class="col-auto">
              <label for="inputPassword2" class="visually-hidden">Password</label>
              <input type="password" class="form-control" id="inputPassword2" placeholder="Password">
          </div>
          <div class="col-auto">
              <button type="submit" class="btn btn-primary mb-3">Submit</button>
          </div>
      </form>
  </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Grid</h5>
    <form>
      <div class="position-relative row mb-3">
        <label for="exampleEmail" class="form-label col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input name="email" id="exampleEmail" placeholder="with a placeholder" type="email" class="form-control">
        </div>
      </div>
      <div class="position-relative row mb-3">
        <label for="examplePassword" class="form-label col-sm-2 col-form-label">Password</label>
        <div class="col-sm-10">
          <input name="password" id="examplePassword" placeholder="password placeholder" type="password" class="form-control">
        </div>
      </div>
      <div class="position-relative row mb-3">
        <label for="exampleSelect" class="form-label col-sm-2 col-form-label">Select</label>
        <div class="col-sm-10">
          <select name="select" id="exampleSelect" class="form-control"></select>
        </div>
      </div>
      <div class="position-relative row mb-3">
        <label for="exampleSelectMulti" class="form-label col-sm-2 col-form-label">Select Multiple</label>
        <div class="col-sm-10">
          <select multiple="multiple" name="selectMulti" id="exampleSelectMulti" class="form-control"></select>
        </div>
      </div>
      <div class="position-relative row mb-3">
        <label for="exampleText" class="form-label form-label col-sm-2 col-form-label">Text Area</label>
        <div class="col-sm-10">
          <textarea name="text" id="exampleText" class="form-control"></textarea>
        </div>
      </div>
      <div class="position-relative row mb-3">
        <label for="exampleFile" class="form-label col-sm-2 col-form-label">File</label>
        <div class="col-sm-10">
          <input name="file" id="exampleFile" type="file" class="">
          <small class="form-text text-muted">This is some placeholder block-level help text for the above
            input. It's a bit lighter and easily wraps to a new line.
          </small>
        </div>
      </div>
      <fieldset class="position-relative row mb-3">
        <legend class="col-form-label col-sm-2">Radio Buttons</legend>
        <div class="col-sm-10">
          <div class="position-relative form-check">
            <label class="form-label form-check-label">
              <input name="radio2" type="radio" class="form-check-input">
              Option one is this and that—be sure to include why it's great
            </label>
          </div>
          <div class="position-relative form-check">
            <label class="form-label form-check-label">
              <input name="radio2" type="radio" class="form-check-input">
              Option two can be something else and selecting it will deselect option one
            </label>
          </div>
          <div class="position-relative form-check disabled">
            <label class="form-label form-check-label">
              <input name="radio2" disabled="disabled" type="radio" class="form-check-input">
               Option three is disabled
            </label>
          </div>
        </div>
      </fieldset>
      <div class="position-relative row mb-3">
        <label for="checkbox2" class="form-label col-sm-2 col-form-label">Checkbox</label>
        <div class="col-sm-10">
          <div class="position-relative form-check">
            <label class="form-label form-check-label">
              <input id="checkbox2" type="checkbox" class="form-check-input">
              Check me out
            </label>
          </div>
        </div>
      </div>
      <div class="position-relative row form-check">
        <div class="col-sm-10 offset-sm-2">
          <button class="btn btn-secondary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
