import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "app-edit-moderator",
  templateUrl: "./edit-moderator.component.html",
  styleUrls: [
    "./edit-moderator.component.sass",
    "../../../layout/styles/modal.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EditModeratorComponent implements OnInit {
  @Input() moderatorId: string;
  @Input() name: string;
  @Input() email: string;

  constructor(private modalService: NgbModal, private service: UsersService) {}

  updateModerator() {
    this.service.editAdmin(this.moderatorId, this.name, this.email).subscribe(
      (response) => {
        console.log(response);
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.error("An error occurred:", error);
      }
    );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnInit(): void {}

  ngOnsubmit() {
    this.updateModerator();
  }
}
