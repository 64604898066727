import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from "./DemoPages/Dashboards/analytics/analytics.component";

// Pages

import { ForgotPasswordBoxedComponent } from "./DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component";
import { LoginBoxedComponent } from "./DemoPages/UserPages/login-boxed/login-boxed.component";
import { RegisterBoxedComponent } from "./DemoPages/UserPages/register-boxed/register-boxed.component";

// Elements

import { StandardComponent } from "./DemoPages/Elements/Buttons/standard/standard.component";
import { DropdownsComponent } from "./DemoPages/Elements/dropdowns/dropdowns.component";
import { CardsComponent } from "./DemoPages/Elements/cards/cards.component";
import { ListGroupsComponent } from "./DemoPages/Elements/list-groups/list-groups.component";
import { TimelineComponent } from "./DemoPages/Elements/timeline/timeline.component";
import { IconsComponent } from "./DemoPages/Elements/icons/icons.component";
import { ModalsComponent } from "./DemoPages/Components/modals/modals.component";
import { PaginationComponent } from "./DemoPages/Components/pagination/pagination.component";

import { ChartjsComponent } from "./DemoPages/Charts/chartjs/chartjs.component";
import { ResetPasswordComponent } from "./DemoPages/UserPages/reset-password/reset-password.component";
import { UsersComponent } from "./DemoPages/users/users.component";
import { PersonalParametersComponent } from "./DemoPages/personal-parameters/personal-parameters.component";
import { ModeratorsComponent } from "./DemoPages/moderators/moderators.component";
import { PaymentComponent } from "./DemoPages/payment/payment.component";
import { AuthGuard } from "./guards/auth.guard";
import { UnauthorizedComponent } from "./DemoPages/unauthorized/unauthorized.component";
import { UserProfileComponent } from "./DemoPages/user-profile/user-profile.component";
import { BlockedUsersComponent } from "./DemoPages/blocked-users/blocked-users.component";
import { FeedbackComponent } from "./DemoPages/feedback/feedback.component";
import { VerificationComponent } from "./DemoPages/verification/verification.component";
const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      // Dashboads
      {
        path: "",
        component: UsersComponent,
        data: { extraParameter: "dashboardsMenu" },
        canActivate: [AuthGuard],
      },
      {
        path: "personal-parameters",
        component: PersonalParametersComponent,
        data: { extraParameter: "dashboardsMenu" },
        canActivate: [AuthGuard],
      },
      {
        path: "moderators",
        component: ModeratorsComponent,
        data: { extraParameter: "dashboardsMenu" },
        canActivate: [AuthGuard],
      },
      {
        path: "payment",
        component: PaymentComponent,
        data: { extraParameter: "dashboardsMenu" },
        canActivate: [AuthGuard],
      },
      {
        path: "analytics",
        component: ChartjsComponent,
        data: { extraParameter: "dashboardsMenu" },
        canActivate: [AuthGuard],
      },
      {
        path: "blocked-users",
        component: BlockedUsersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "user-profile/:id",
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "user-verification/:id",
        component: VerificationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "feedback",
        component: FeedbackComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "",
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: "pages/login",
        component: LoginBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/register-boxed",
        component: RegisterBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/forgot-password-boxed",
        component: ForgotPasswordBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/reset-password-boxed",
        component: ResetPasswordComponent,
        data: { extraParameter: "" },
      },
    ],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
