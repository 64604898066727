import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";
@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: [
    "./edit-user.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserComponent {
  @Input() UserId: string;
  @Input() name: string;
  @Input() email: string;

  constructor(private modalService: NgbModal, private service: UsersService) {}

  updateUser() {
    this.service.editUser(this.UserId, this.name, this.email).subscribe(
      (response) => {
        console.log(response);
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.error("An error occurred:", error);
      }
    );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnSubmit() {
    this.updateUser();
  }
}
