<div class="personal-parameters">
    <div class="justify-content-between head d-flex mb-2">
        <div>
            <h1 class="title title-large main-color">إدارة الصفات الشخصية ({{ length }})</h1>
        </div>
        <div>
            <app-search (searchTextChanged)="searchText = $event"></app-search>
        </div>
    </div>
    <div>
        <app-add-parameter></app-add-parameter>
    </div>
    <div>
        <app-parameters-table [searchText]="searchText" (length)="length=$event" ></app-parameters-table>
    </div>
</div>