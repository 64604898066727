<ng-template #content let-modal class="rounded-2">
    <div class="modal-header">
        <h1 class="modal-title main-color title">إنشاء صفة جديدة</h1>
        <div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
    </div>
    <div class="modal-body">
        <div class="container" ngbDropdown>
            <p class="main-color regular-16">نوع الصفة :
                <span *ngIf="selectedTypeId">{{ selectedTypeName }}</span>
            </p>
            <button type="button" ngbDropdownToggle class="mb-2 me-2 btn menu-button" style="direction: rtl; ">
                <span class="regular-16">اختر نوع الصفة</span>
            </button>
            <div ngbDropdownMenu style="direction: rtl; text-align: start;">
                <button *ngFor="let type of types" (click)="onTypeClick(type.id)" class="dropdown-item">
                    {{ type.name }}
                </button>
            </div>
        </div>
        <div class="container" *ngIf="[0, 1].includes(selectedTypeId)">
            <button class="btn" (click)="addDiv()">أضف صفة</button>
        </div>
        <form (ngSubmit)="addParameter()" class="p-0">
            <div class="container">
                <input class="form-control" placeholder="الصفة بالعربية" [(ngModel)]="parameter_ar"
                    name="parameter_ar" />
                <input class="form-control" placeholder="الصفة بالإنجليزية" [(ngModel)]="parameter_en"
                    name="parameter_en" />
                <div class="container" *ngIf="[0, 1].includes(selectedTypeId)">
                    <hr class="hr" />
                    <div *ngFor="let value of values; let i = index">
                        <div class="container">
                            <input class="form-control" placeholder="قيمة بالعربية" [(ngModel)]="values[i].value_ar"
                                name="value_ar[i]" />
                            <input class="form-control" placeholder="قيمة بالإنجليزية" [(ngModel)]="values[i].value_en"
                                name="value_en[i]" />
                        </div>
                        <hr class="hr" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn button-large">حفظ</button>
            </div>
        </form>
    </div>
</ng-template>

<button (click)="openmodal(content)" class="border-0 btn p-0">
    <a class="me-1 main-color" style="font-size: 20px ;"><ins>إضافة صفة</ins></a>
    <span class="material-icons main-color mx-2" style="font-size: 20px ;">+</span>
</button>