<div class="users-table">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px;">
        <div class="form-group col-md-4">
            <input class="form-control search py-2" type="search" placeholder="يبحث" id="example-search-input">
        </div>
        <!-- <div class="form-group col-md-4">
            <div class="input-group">
              <input class="form-control search py-2" type="search" placeholder="يبحث" id="example-search-input">
              <div class="input-group-append">
                <span class="input-group-text">
                    <i class="bi bi-search"></i>
                </span>
              </div>
            </div>
          </div> -->
        <h1 class="title title-large main-color">إدارة المستخدم</h1>
    </div>
    <app-regular></app-regular>
    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between">
            <button class="btn btn-lg button-large">إرسال إشعار للمستخدمين</button>
            <button class="btn btn-lg btn-outline main-color">إرسال رسالة عبر النشرة الإخبارية</button>
        </div>
        <div class="d-flex">
            <p class="main-color">1000 users</p>
            <ngb-pagination [collectionSize]="70" [(page)]="page" [boundaryLinks]="true"></ngb-pagination>
            <!-- <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page">
</mat-paginator> -->
        </div>
    </div>
</div>