<!-- <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Country</th>
      <th scope="col">Area</th>
      <th scope="col">Population</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let country of countries; index as i">
      <th scope="row">{{ i + 1 }}</th>
      <td>
        <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="me-2"
             style="width: 20px">
        {{ country.name }}
      </td>
      <td>{{ country.area | number }}</td>
      <td>{{ country.population | number }}</td>
    </tr>
    </tbody>
  </table> -->

<div class="d-md-flex justify-content-between">
  <div class="d-flex flex-wrap" style="gap: 15px;">
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">الإالتزام
          الديني</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span
          class="label-16">تعليم</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span
          class="label-16">إشغال</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">مبنى
          الجسم</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">لون
          البشرة</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">لون
          الشعر</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">لون
          العين</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline"><span
          class="label-16">إرتفاع</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
        <button class="dropdown-item">Settings</button>
        <h6 class="dropdown-header">Header</h6>
        <button class="dropdown-item">Actions</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item">Dividers</button>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" ngbDropdownToggle class="mb-2 me-2 btn btn-outline "><span class="label-16">مكان
          الإقامة</span></button>
      <div ngbDropdownMenu>
        <button class="dropdown-item">Menus</button>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <button class="border-0 btn">
      <span class="material-icons main-color mx-2" style="font-size: 20px ;">+</span>
      <a class="me-1 main-color" style="font-size: 20px ;"><ins>إضافة مسؤول جديد</ins></a>
    </button>
    <button class="border-0 btn">
      <span class="material-icons main-color mx-2" style="font-size: 20px ;">+</span>
      <a class="me-1 main-color" style="font-size: 20px ;"><ins>إضافة مشرف</ins></a>
    </button>
  </div>
</div>

<div class="mb-3">
  <div class="table-responsive mb-5">
    <table class="align-middle table-responsive text-truncate mb-0 table">
      <thead class="regular-18 main-color">
        <tr class="ta">
          <th class="text-center"></th>
          <th class="text-center">الإلتزام الديني</th>
          <th class="text-center">تعليم</th>
          <th class="text-center">إشغال</th>
          <th class="text-center">مبنى الجسم</th>
          <th class="text-center">لون البشرة</th>
          <th class="text-center">لون الشعر</th>
          <th class="text-center">لون العين</th>
          <th class="text-center">إرتفاع</th>
          <th class="text-center">وزن</th>
          <th class="text-center">مكان الإقامة</th>
          <th class="text-center">بريد إلكتروني</th>
          <th class="text-center">اسم</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">
            <div role="group" class="btn-group-sm btn-group">
              <i class="fa fa-solid fa-trash" style="color: #B9B9B9;"></i>
              <img src="../../../../assets/components/icons/Vector (1).svg" style="color: black;" alt="">
            </div>
          </td>
          <td class="text-center">مسلم</td>
          <td class="text-center">
            تعليم عالي
          </td>
          <td class="text-center">محمد صلاح</td>
          <td class="text-center">حمادة هلال</td>

          <td class="text-center">
            أزرق

          </td>
          <td class="text-center" style="width: 200px;">
            أحمر
          </td>
          <td class="text-center" style="width: 200px;">
            أصفر
          </td>
          <td class="text-center" style="width: 200px;">
            أصفر
          </td>
          <td class="text-center" style="width: 200px;">
            50
          </td>
          <td class="text-center" style="width: 200px;">
            الاسكندرية
          </td>
          <td class="text-center" style="width: 200px;">
            hamada@gmail.com
          </td>
          <td class="text-center" style="width: 200px;">
            حمادة
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div role="group" class="btn-group-sm btn-group">
              <i class="fa fa-solid fa-trash" style="color: #B9B9B9;"></i>
            </div>
          </td>
          <td class="text-center">مسلم</td>
          <td class="text-center">
            تعليم عالي
          </td>
          <td class="text-center">محمد صلاح</td>
          <td class="text-center">حمادة هلال</td>

          <td class="text-center">
            أزرق

          </td>
          <td class="text-center" style="width: 200px;">
            أحمر
          </td>
          <td class="text-center" style="width: 200px;">
            أصفر
          </td>
          <td class="text-center" style="width: 200px;">
            أصفر
          </td>
          <td class="text-center" style="width: 200px;">
            50
          </td>
          <td class="text-center" style="width: 200px;">
            الاسكندرية
          </td>
          <td class="text-center" style="width: 200px;">
            hamada@gmail.com
          </td>
          <td class="text-center" style="width: 200px;">
            حمادة
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>