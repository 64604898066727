import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "app-delete-user",
  templateUrl: "./delete-user.component.html",
  styleUrls: [
    "./delete-user.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteUserComponent {
  @Input() UserId: string;
  @Input() DeleteReason: string = "0";
  showOtherReasonInput: boolean = false;

  constructor(private modalService: NgbModal, private service: UsersService) {}

  handleSelectChange(value: string): void {
    this.DeleteReason = value;
    this.showOtherReasonInput = value === "other";
  }

  deleteUser() {
    this.service.deleteUser(this.UserId, this.DeleteReason).subscribe(
      (response) => {
        console.log("user" + this.UserId + "deleted for" + this.DeleteReason);
        this.modalService.dismissAll();
        location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  close() {
    this.DeleteReason = "0";
    this.modalService.dismissAll();
    location.reload();
  }
  ngOnInit() {}
}
