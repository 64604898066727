<div class="moderators">
    <div class="justify-content-between head d-flex mb-2">
        <div (collectionSize)="collectionSize">
            <h1 class="title title-large main-color" >المشرفين ( {{collectionSize}} )</h1>
        </div>
        <div>
            <app-search (searchTextChanged)="searchText = $event"></app-search>
        </div>
    </div>
    <div class="d-flex" style="gap: 45px; direction: rtl;">
        <div>
            <app-add-moderator></app-add-moderator>
        </div>
        <div>
            <app-add-admin></app-add-admin>
        </div>
    </div>
    <app-moderators-table [searchText]="searchText" (collectionSize)="collectionSize=$event"></app-moderators-table>
</div>