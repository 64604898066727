import { Component, OnInit } from "@angular/core";
import { ParametersService } from "src/app/services/parameters/parameters.service";
@Component({
  selector: "app-personal-parameters",
  templateUrl: "./personal-parameters.component.html",
  styleUrls: [
    "./personal-parameters.component.scss",
    "../../layout/styles/buttons.scss",
    "../../layout/styles/fonts.scss",
    "../../layout/styles/modal.scss",
    "../../layout/styles/table.scss",
  ],
})
export class PersonalParametersComponent {
  searchText: any = "";
  filteredParams: any = [];
  parameters: any = [];
  length: number = 0;

  constructor(private service :ParametersService) {}

  filterParameters() {
    this.filteredParams = this.parameters.filter((param) => {
      const term = this.searchText.toLowerCase();
      return Object.values(param).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        }
        if (typeof value === "number") {
          const stringValue = value.toString();
          return stringValue.toLowerCase().includes(term);
        }
        return false;
      });
    });
  }

  ngOnChanges() {
    if (this.searchText.trim() === "") {
      this.filteredParams = this.parameters;
      console.log(this.filteredParams);
    } else {
      this.filterParameters();
    }
  }
}
