import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/auth.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private apiUrl: string = `${environment.API_URL}/api/Payment`;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  getAllPayments(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetAllPaymentPlains`).pipe(
      tap((response) => {
        console.log(response);
      }),
      catchError((error) => {
        console.error("An error occurred:", error);
        return throwError(error);
      })
    );
  }

  addPayment(NumOfMonths: string, Amount: string): Observable<any> {
    const inputs = new FormData();
    inputs.append("NumOfMonths", NumOfMonths);
    inputs.append("Amount", Amount);
    return this.http
      .post<any>(
        `${this.apiUrl}/AddPaymentPlain`,
        JSON.stringify({
          NumOfMonths,
          Amount,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/json",
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  updatePayment(
    PaymentPlainId: string,
    NumOfMonths: string,
    Amount: string
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("NumOfMonths", NumOfMonths);
    inputs.append("Amount", Amount);
    return this.http
      .put<any>(
        `${this.apiUrl}/UpdatePaymentPlain?PaymentPlainId${PaymentPlainId}`,
        {
          NumOfMonths,
          Amount,
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  deletePayment(PaymentPlainId: string): Observable<any> {
    return this.http
      .delete<any>(
        `${this.apiUrl}/DeletePaymentPlain?PaymentPlainId=${PaymentPlainId}`,
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(`Payment ${PaymentPlainId} deleted`);
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllSubscriptions(
    Page: number,
    PagesLimit: number,
    MonthsNum?:number
  ): Observable<any> {
    const params: any = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
    };
    if (MonthsNum !== undefined) {
      params.MonthsNum = MonthsNum.toString();
    }
    return this.http
      .get<any>(`${this.apiUrl}/GetAllSubscriptions`, {
        params,
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  addSubscription(
    StartTime: string,
    EndTime: string,
    ConfirmNumber: string,
    TransactionId: string,
    MonthsNum: string,
    Amount: any
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("StartTime", StartTime);
    inputs.append("EndTime", EndTime);
    inputs.append("ConfirmNumber", ConfirmNumber);
    inputs.append("TransactionId", TransactionId);
    inputs.append("MonthsNum", MonthsNum);
    inputs.append("Amount", Amount);
    return this.http
      .post<any>(
        `${this.apiUrl}/HomeUserAddSubscription`,
        JSON.stringify({
          StartTime,
          EndTime,
          ConfirmNumber,
          TransactionId,
          MonthsNum,
          Amount,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/json",
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  addDashboardSubscription(
    UserId: string,
    StartTime: string,
    EndTime: string,
    ConfirmNumber: string,
    TransactionId: string,
    MonthsNum: string,
    Amount: any
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("StartTime", StartTime);
    inputs.append("EndTime", EndTime);
    inputs.append("ConfirmNumber", ConfirmNumber);
    inputs.append("TransactionId", TransactionId);
    inputs.append("MonthsNum", MonthsNum);
    inputs.append("Amount", Amount);
    return this.http
      .post<any>(
        `${this.apiUrl}/DashboardAddSubscription?UserId=${UserId}`,
        JSON.stringify({
          StartTime,
          EndTime,
          ConfirmNumber,
          TransactionId,
          MonthsNum,
          Amount,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/json",
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  updateSubscription(
    SubscriptionId: string,
    StartTime: string,
    EndTime: string,
    ConfirmNumber: string,
    TransactionId: string,
    MonthsNum: string,
    Amount: any
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("StartTime", StartTime);
    inputs.append("EndTime", EndTime);
    inputs.append("ConfirmNumber", ConfirmNumber);
    inputs.append("TransactionId", TransactionId);
    inputs.append("MonthsNum", MonthsNum);
    inputs.append("Amount", Amount);
    return this.http
      .put<any>(
        `${this.apiUrl}/UpdateSubscription?SubscriptionId=${SubscriptionId}`,
        {
          StartTime,
          EndTime,
          ConfirmNumber,
          TransactionId,
          MonthsNum,
          Amount,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }
}
