import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private apiUrl: string = `${environment.API_URL}/api/Account`;
  private apiUrl2: string = `${environment.API_URL}/api/Dashboard`;
  private tokenKey = "auth_token";

  constructor(private http: HttpClient) {}

  // register new user
  addAdmin(
    role: any,
    Name: string,
    Email: string,
    Password: string,
    PhoneNumber: string,
    ConfirmPassword: string
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("Name", Name);
    inputs.append("Email", Email);
    inputs.append("Password", Password);
    inputs.append("PhoneNumber", PhoneNumber);
    inputs.append("ConfirmPassword", ConfirmPassword);
    return this.http
      .post<any>(`${this.apiUrl2}/AddAdmin?role=${role}`, inputs, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          const token = response?.token;
          if (token) {
            this.storeToken(token);
          }
        })
      );
  }

  // Supervisor Admin
  setUserRole(UserId: string, RoleName: string): Observable<any> {
    const input = new FormData();
    input.append("UserId", UserId);
    input.append("RoleName", RoleName);
    return this.http.post<any>(`${this.apiUrl2}/AddRoleToUser`, input, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  }

  // login
  login(email: string, password: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiUrl}/Login`,
        { email, password },
        {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .pipe(
        tap((response) => {
          const token = response.token;
          this.storeToken(token);
        })
      );
  }

  // logout
  logout(): void {
    localStorage.removeItem(this.tokenKey);
  }

  // store user
  setloggedUser(user?: any) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  // send email verification code to reset password
  forgotPassword(Value: string): Observable<any> {
    console.log("forgotPassword", Value);
    return this.http.post<any>(`${this.apiUrl}/ForgetPassword`, {
      Value: Value,
    });
  }

  // reset password
  resetPassword(
    email: string,
    password: string,
    confirmPassword: string,
    token: string
  ): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/ResetPassword`, {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      token: token,
    });
  }

  // Store the token in local storage
  storeToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  // Remove the token from the local storage
  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  // Check if a token exists in local storage
  isLoggedIn(): boolean {
    const token = this.getToken();
    return !!token; // Returns true if token is present, false otherwise
  }

  // retrieve token from local storage
  getToken(): string {
    return localStorage.getItem(this.tokenKey);
  }
}
