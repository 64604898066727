import {
  Component,
  Input,
  OnInit,
  Output,
  TemplateRef,
  EventEmitter,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users/users.service";
import { ParametersService } from "src/app/services/parameters/parameters.service";
@Component({
  selector: "app-edit-user-profile-parameters",
  templateUrl: "./edit-user-profile-parameters.component.html",
  styleUrls: [
    "./edit-user-profile-parameters.component.sass",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
})
export class EditUserProfileParametersComponent implements OnInit {
  user: any;
  @Input() UseId: string;
  lang: string = "ar";
  Gender: any;
  Name: string;
  BirthYear: any;
  CityId: any;
  AreaId: any;
  MaritalStatus: any;
  Height: any;
  Weight: any;
  IsSmoking: any;
  selectionModel: {
    parameterId: any;
    valueId: any;
    value: string;
  }[] = [];
  genders = [
    { id: 0, name: "رجل" },
    { id: 1, name: "سيدة" },
  ];
  selectedCity;
  selectedGenderName = "";
  selectedSmokingPreference: boolean;
  allCities: any[];
  selectedGender: EventEmitter<number> = new EventEmitter<number>();
  selectedSmoking: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private service: UsersService,
    private parametersService: ParametersService
  ) {}

  getAllCities() {
    this.service.getAllCities().subscribe(
      (response) => {
        this.allCities = response.map((city) => ({
          id: city.id,
          name: city.city,
        }));
        this.allCities = response;
        console.log("koty", response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onCityClick(cityId: number): void {
    console.log("Selected city ID:", cityId);
  }
  getUserById() {
    this.service.getUserById(this.UseId).subscribe(
      (res: any) => {
        this.user = res;
        this.selectedGenderName = res.gender;
        this.Name = res.name;
        this.BirthYear = res.BirthYear;
        this.CityId = res.city;
        this.AreaId = res.area;
        this.MaritalStatus = res.MaritalStatus;
        this.Height = res.height;
        this.Weight = res.weight;
        this.selectedSmokingPreference = res.isSmoking;
        this.selectionModel = res.parameters[0].valueId;
        console.log("this is the user radwa ", res);
        console.log("7mada", this.selectedSmokingPreference);
      },
      (error) => {
        error = error?.message || "An error occurred during adding new user.";
        console.log(error);
      }
    );
  }

  updateUserSetup() {
    this.service
      .updateUserSetup(
        this.Gender,
        this.UseId,
        this.Name,
        this.MaritalStatus,
        this.BirthYear,
        this.CityId,
        this.AreaId,
        this.selectionModel,
        this.Height,
        this.Weight,
        this.lang,
        this.IsSmoking
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.modalService.dismissAll();
          location.reload();
        },
        (error) => {
          console.error("An error occurred:", error);
        }
      );
  }

  onValueClick(value: number): void {
    this.selectedGender.emit(value);
    this.Gender = value;
    const selectedGender = this.genders.find((gender) => gender.id === value);

    // Update the selected gender name
    this.selectedGenderName = selectedGender ? selectedGender.name : "";
    console.log("value clicked ", this.selectedGender);
  }

  onSmokingPreferenceClick(preference: boolean): void {
    this.selectedSmoking.emit(preference);
    this.selectedSmokingPreference = preference;
    this.IsSmoking = preference;
    console.log("smokeeeeeeee", this.selectedSmoking);
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }

  ngOnInit(): void {
    this.getUserById();
    this.getAllCities();
    console.log("the usersssssss", this.UseId);
    console.log("selection zeft", this.selectionModel);
    console.log("hmawet nafsy", this.selectedSmokingPreference);
  }
}
