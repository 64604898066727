<div class="payments">
  <div class="justify-content-between head d-flex mb-2">
    <div (collectionSize)="(collectionSize)">
      <h1 class="title title-large main-color">
        إدارة الدفع ( {{ collectionSize }} )
      </h1>
    </div>
    <div>
      <app-search (searchTextChanged)="searchText = $event"></app-search>
    </div>
  </div>
  <app-payment-table
    [searchText]="searchText"
    (collectionSize)="collectionSize = $event"
  ></app-payment-table>
</div>
