import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, BaseChartDirective, Label } from "ng2-charts";
// import * as pluginAnnotations from "chartjs-plugin-annotation";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: [
    "../../../../../layout/styles/buttons.scss",
    "../../../../../layout/styles/fonts.scss",
    "../../../../../layout/styles/modal.scss",
  ],
})
export class LineChartComponent {
  // public lineChartData: ChartDataSets[] = [
  //   {
  //     data: [],
  //     label: "المستخدمون الجدد كل فترة",
  //   },
  // ];
  @Input() dynamicChartData: ChartDataSets[] = [];
  @Input() lineChartLabels: Label []=[];
  chartTitleContent = "";

  // public lineChartLabels: Label[] = ["0W", "1W", "2W", "3W", "4W"];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          position: "bottom",
          gridLines: {
            color: "#FFE9F3",
          },
          ticks: {
            fontColor: "#A12D63",
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
          gridLines: {
            color: "#FFE9F3",
          },
          ticks: {
            fontColor: "#A12D63",
            beginAtZero: true,
            stepSize: 10,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
  };
  public lineChartColors: Color[] = [
    {
      // main
      backgroundColor: "transparent",
      borderColor: "#A12D63",
      pointBackgroundColor: "#A12D63",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#A12D63",
    },
  ];
  public lineChartLegend = true;
  public lineChartType = "line";
  // public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() {}

  // public randomize(): void {
  //   for (let i = 0; i < this.lineChartData.length; i++) {
  //     for (let j = 0; j < this.lineChartData[i].data.length; j++) {
  //       this.lineChartData[i].data[j] = this.generateNumber(i);
  //     }
  //   }
  //   this.chart.update();
  // }

  private generateNumber(i: number) {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public hideOne() {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne() {
    this.dynamicChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  }

  public changeColor() {
    this.lineChartColors[2].borderColor = "#FFE9F3";
    this.lineChartColors[2].backgroundColor = `#FFE9F3`;
  }

  public changeLabel() {
    this.lineChartLabels[2] = ["1st Line", "2nd Line"];
    // this.chart.update();
  }
}
