<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn ngbDropdownToggle no-arrow" ngbDropdownToggle>
              <span>
                <img width="42" src="./assets/images/dropdown-header/Profile.svg" alt="user"
                  style="height: 24px; width: 20px;">
              </span>
            </button>
            <div ngbDropdownMenu>
              <button (click)="logout()" class="dropdown-item main-color" style="direction: rtl; text-align: start;">
                <span>تسجيل خروج</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>