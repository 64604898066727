import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import {
  NgbDateAdapter,
  NgbDateStruct,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";

import { CustomAdapter } from "./all-data/all-data.component";

@Component({
  selector: "app-chart-header",
  templateUrl: "./chart-header.component.html",
  styleUrls: [
    "../../../../../layout/styles/buttons.scss",
    "../../../../../layout/styles/fonts.scss",
    // "../../../../../layout/styles/modal.scss",
  ],
  providers: [{ provide: NgbDateAdapter, useClass: CustomAdapter }],
})
export class ChartHeaderComponent {
  @Input() chartTitle: string = "";
  @Output() dateSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() count : string;
  selectedDate: any;
  constructor(private modalService: NgbModal) {}

  onDateSelected(date: any) {
    this.selectedDate = date;
    this.dateSelected.emit(date);
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
  }
 
}
