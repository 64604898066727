import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ParametersService } from "src/app/services/parameters/parameters.service";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-edit-parameter",
  templateUrl: "./edit-parameter.component.html",
  styleUrls: [
    "./edit-parameter.component.scss",
    "../../../layout/styles/buttons.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/modal.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EditParameterComponent implements OnInit {
  @Input() ParameterId: string;
  title: string;
  parameter: string;
  closeResult: string;
  values: { value_ar: string; value_en: string }[] = [];
  parameter_en: string;
  parameter_ar: string;
  // initialTypeId: number = 1;
  type: number;
  selectedTypeId: number | null = null;
  selectedTypeName: any | null = null;
  types: any[] = [
    { id: 0, name: "إختيارات" },
    { id: 1, name: "إختيار متعدد" },
    { id: 2, name: "نصٌ" },
    { id: 3, name: "تاريخ" },
    { id: 4, name: "رقم" },
  ];
  constructor(
    private modalService: NgbModal,
    private service: ParametersService
  ) {}

  getselectedTypeName(): void {
    const selectedType = this.types.find(
      // here the type is selected
      (selectedTypeId) => selectedTypeId.id === this.selectedTypeId
    );
    this.selectedTypeName = selectedType ? selectedType.name : null;
  }

  ngOnInit(): void {
    // Set the initially returned type
    // this.selectedTypeName = this.types.find((type) => type.id === this.type);
    // this.getselectedTypeName()
    console.log("first", this.selectedTypeName);
    // this.onTypeClick(this.type);
  }

  onTypeClick(typeId: number): void {
    this.selectedTypeId = typeId;
    this.updateSelectedTypeName();
  }

  updateSelectedTypeName(): void {
    const selectedType = this.types.find(
      // here the type is selected
      (selectedTypeId) => selectedTypeId.id === this.selectedTypeId
    );
    this.selectedTypeName = selectedType ? selectedType.name : null;
    console.log("olaaa", selectedType);
  }
  getParameter(): void {
    this.service.getParameter(this.ParameterId).subscribe(
      (response) => {
        this.parameter_ar = response.title_ar;
        this.parameter_en = response.title_en;
        this.values = response.values;
        this.selectedTypeName = this.types.find(
          (type) => type.id === response.parameterType
        );
        this.type = this.selectedTypeName ? this.selectedTypeName.name : ""; // Set type name, or empty string if not found
        console.log("parameter :", this.ParameterId, this.type);
      },
      (error) => {
        console.error("An error occurred:", error);
      }
    );
  }

  addDiv() {
    this.values.push({ value_ar: "", value_en: "" });
    console.log("values", this.values);
  }
  updateParameter() {
    const parameterData = {
      parameter_en: this.parameter_en,
      parameter_ar: this.parameter_ar,
      parameterType: this.selectedTypeId,
      values: this.values || [],
    };
    this.service
      .updateParameter(
        this.ParameterId,
        parameterData.parameter_ar,
        parameterData.parameter_en,
        parameterData.parameterType,
        parameterData.values
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.modalService.dismissAll();
          location.reload();
        },
        (error) => {
          console.error("An error occurred:", error);
        }
      );
  }

  openmodal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    this.getParameter();
  }
}
