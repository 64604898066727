import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class UsersService {
  private apiUrl: string = `${environment.API_URL}/api/Dashboard`;
  private apiUrl2: string = `${environment.API_URL}/api/HomeUser`;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // getAllUsers(Page: number, PagesLimit: number): Observable<any> {
  //   const params = {
  //     Page: Page.toString(),
  //     PagesLimit: PagesLimit.toString(),
  //   };

  //   return this.http.get<any>(`${this.apiUrl}/GetAllUsers`, { params }).pipe(
  //     tap((response) => {
  //       console.log(response);
  //     }),
  //     catchError((error) => {
  //       console.error("An error occurred:", error);
  //       return throwError(error);
  //     })
  //   );
  // }

  getUsersCount(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetCountUsers`).pipe(
      tap((response) => {
        console.log(response);
      }),
      catchError((error) => {
        console.error("An error occurred:", error);
        return throwError(error);
      })
    );
  }

  editUser(UserId: string, name: string, email: string): Observable<any> {
    return this.http
      .put<any>(
        `${this.apiUrl}/EditUser?UserId=${UserId}`,
        { name, email },
        { headers: { Authorization: "Bearer " + this.authService.getToken() } }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  deleteUser(UserId: string, DeleteReason: string): Observable<any> {
    return this.http
      .put<any>(
        `${this.apiUrl}/DeleteUser?UserId=${UserId}&DeleteReason=${DeleteReason}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllUsersFiltered(
    Page: number,
    PagesLimit: number,
    parameterId: any | "",
    valueId: any | "",
    lang: string
  ): Observable<any> {
    const params = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
      lang: lang ?? "",
    };
    return this.http
      .get<any>(
        `${this.apiUrl}/GetAllUsers?parameterId=${parameterId ?? ""}&valueId=${
          valueId ?? ""
        }`,
        {
          params,
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllAdmins(Page: number, PagesLimit: number): Observable<any> {
    const params = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
    };
    return this.http
      .get<any>(`${this.apiUrl}/GetAllAdmins`, {
        params,
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllAdminsByRole(
    Page: number,
    PagesLimit: number,
    Role: string
  ): Observable<any> {
    const params = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
      Role,
    };
    return this.http
      .get<any>(`${this.apiUrl}/GetAllAdmins/`, {
        params,
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  editAdmin(UserId: string, name: string, email: string): Observable<any> {
    return this.http
      .put<any>(
        `${this.apiUrl}/EditAdmin?UserId=${UserId}`,
        { name, email },
        { headers: { Authorization: "Bearer " + this.authService.getToken() } }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  deleteAdmin(AdminId: string): Observable<any> {
    return this.http
      .delete<any>(`${this.apiUrl}/DeleteAdmin?AdminId=${AdminId}`)
      .pipe(
        tap((response) => {
          console.log(`Admin ${AdminId} deleted`);
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getUserById(UserId: string, lang: string = "ar"): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/GetProfileById?UserId=${UserId}&lang=${lang}`, {
        headers: { Authorization: "Bearer " + this.authService.getToken() },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  blockUser(UserId: string): Observable<any> {
    return this.http
      .put<any>(
        `${this.apiUrl}/BlockUser?UserId=${UserId}`,
        {},
        {
          headers: { Authorization: "Bearer " + this.authService.getToken() },
        }
      )
      .pipe(
        tap((response) => {
          console.log(`User ${UserId} blocked`);
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  UnBlockUser(UserId: string): Observable<any> {
    return this.http
      .put<any>(
        `${this.apiUrl}/UnBlockUser?UserId=${UserId}`,
        {},
        {
          headers: { Authorization: "Bearer " + this.authService.getToken() },
        }
      )
      .pipe(
        tap((response) => {
          console.log(`User ${UserId} blocked`);
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllBlockedUsers(Page: number, PagesLimit: number): Observable<any> {
    const params = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
    };
    return this.http
      .get<any>(`${this.apiUrl}/GetAllFeedbackUsers`, {
        params,
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getLikedUsersById(UserId: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/LikedListById?UserId=${UserId}`, {
        headers: { Authorization: "Bearer " + this.authService.getToken() },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getWhoLikedUserById(UserId: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/WhoLikedListById?UserId=${UserId}`, {
        headers: { Authorization: "Bearer " + this.authService.getToken() },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  updateUserSetup(
    UserId: string,
    lang: string = "ar",
    Gender: any,
    Name: string,
    BirthYear: any,
    CityId: any,
    AreaId: any,
    MaritalStatus: any,
    Height: any,
    Weight: any,
    IsSmoking: any,
    selectionModel: {
      parameterId: any;
      valueId: any;
      value: string;
    }[]
  ): Observable<any> {
    const inputs = new FormData();
    inputs.append("Gender", Gender);
    inputs.append("Name", Name);
    inputs.append("BirthYear", BirthYear);
    inputs.append("CityId", CityId);
    inputs.append("AreaId", AreaId);
    inputs.append("MaritalStatus", MaritalStatus);
    inputs.append("Height", Height);
    inputs.append("Weight", Weight);
    inputs.append("IsSmoking", IsSmoking);
    inputs.append("parameters", JSON.stringify(selectionModel));
    return this.http
      .put<any>(
        `${this.apiUrl}/UpdateAccountSetupByID/?UserId=${UserId}&lang=${lang}`,
        {
          Gender,
          Name,
          BirthYear,
          CityId,
          AreaId,
          MaritalStatus,
          Height,
          Weight,
          IsSmoking,
          selectionModel,
        },
        {
          headers: {
            Authorization: "Bearer " + this.authService.getToken(),
          },
        }
      )
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.error("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllCities(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl2}/GetAllCity`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllArea(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl2}/GetAllArea`, {
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }

  getAllFeedback(Page: number, PagesLimit: number): Observable<any> {
    const params = {
      Page: Page.toString(),
      PagesLimit: PagesLimit.toString(),
    };
    return this.http
      .get<any>(`${this.apiUrl}/GetAllFeedbackApplication`, {
        params,
        headers: {
          Authorization: "Bearer " + this.authService.getToken(),
        },
      })
      .pipe(
        tap((response) => {
          console.log(response);
        }),
        catchError((error) => {
          console.log("An error occurred:", error);
          return throwError(error);
        })
      );
  }
}
