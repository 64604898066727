<div class="users-table">
    <div class="table-responsive mb-5">
        <table class="align-middle table-responsive text-truncate mb-0 table">
            <thead>
                <tr class="ta">
                    <th class="text-center" (click)="sortUsers()" style="cursor: pointer;">الاسم</th>
                    <th class="text-center">المرسل</th>
                    <th class="text-center">السبب</th>
                    <th class="text-center">رسالة التقرير</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let blocked of filteredUsers ; index as i">
                    <td class="text-center">
                    <a class="main-color" [routerLink]="['/user-profile', blocked.id]">
                        {{blocked.name}}
                    </a>
                    </td>
                    <td class="text-center">
                        {{blocked.senderName}}
                    </td>
                    <td class="text-center">
                        {{blocked.reason}}
                    </td>
                    <td class="text-center">
                        {{blocked.report}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-between table-footer">
        <div class="d-flex col-sm-6 justify-content-start pagination-container">
            <ngb-pagination id="page" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refresh()">
            </ngb-pagination>
            <select class="select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refresh()">
                <option [ngValue]="5">5/صفحة </option>
                <option [ngValue]="10">10/صفحة</option>
                <option [ngValue]="15">15/صفحة</option>
            </select>
        </div>
    </div>
</div>