import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ParametersService } from "src/app/services/parameters/parameters.service";
import { UsersService } from "src/app/services/users/users.service";
@Component({
  selector: "app-parameters",
  templateUrl: "./parameters.component.html",
  styleUrls: [
    "./parameters.component.scss",
    "../../../layout/styles/fonts.scss",
  ],
})
export class ParametersComponent implements OnInit {
  parameters: string[] = [];
  @Output() parameterClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueClicked: EventEmitter<number> = new EventEmitter<number>();
   selectedPara :number;
  constructor(
    private service: ParametersService,
  ) {}

  onButtonClick(parameterId: number): void {
    this.parameterClicked.emit(parameterId) ;
    this.selectedPara = parameterId;
    console.log("para clicked ", this.parameterClicked)
  }
  
  onValueClick(valueId: number): void {
    this.valueClicked.emit(valueId);
    console.log("value clicked ", this.valueClicked)
  }
 
  ngOnInit(): void {
    this.service.getAllParameters().subscribe(
      (parameters) => {
        this.parameters = parameters;
        console.log(this.parameters);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
