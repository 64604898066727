<div class="h-100 text-center">
  <div class="mt-5">
    <img src="../../../../assets/images/Logo.png" alt="zawaj">
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <div class="dialog mt-5 mx-auto col-md-8">
      <div class="text-center">
        <h1 class="title-large">إعادة تعيين كلمة المرور</h1>
        <p class="sub-title mb-4" style="margin-top: 12px;">أدخل عنوان بريدك الإلكتروني في حقل الإدخال أدناه.</p>
      </div>
      <form (ngSubmit)="forgot(Value)">
        <div role="group" class="mb-3">
          <div class="mb-3">
            <label for="email" class="label-16 mb-2">بريد إالكتروني</label>
            <input name="email" type="email" placeholder="اكتب بريدك الإلكتروني هنا" required [(ngModel)]="Value"
              class="form-control">
          </div>

          <div class="sub-title mt-3 mb-1">
            <div>تم إرسال تعليمات الاسترداد إلى عنوان البريد الإلكتروني</div>
          </div>
          <button type="submit" class="btn btn-lg mt-4 button-large">إرسال الرمز
          </button>
        </div>
      </form>
    </div>
  </div>
</div>