import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: [
    "./reset-password.component.sass",
    "../user-pages.scss",
    "../../../layout/styles/fonts.scss",
    "../../../layout/styles/buttons.scss",
    "../user-pages.scss",
  ],
})
export class ResetPasswordComponent implements OnInit {
  email: string = "Admin@email.com";
  password: string;
  confirmPassword: string;
  token: string =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJOVUxMIiwianRpIjoiNmFhYWUzNjEtODM3ZC00Y2E5LTg1NGYtODdhMzg0MGYyY2YyIiwiZW1haWwiOiJhZG1pbkBlbWFpbC5jb20iLCJ1aWQiOiJjNDc5OTQ1Ny05N2NhLTRlNzMtODYzYy1kMzRmMTMzODM2MzgiLCJyb2xlcyI6IkFkbWluIiwiZXhwIjoxNzA5ODk1MTA0LCJpc3MiOiJTZWN1cmVBcGkiLCJhdWQiOiJTZWN1cmVBcGlVc2VyIn0.U_eMPFsgXhcuH9rY0Ulum3pAq1n1-Yg32F1X65NVBKk";
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  resetPassword(): void {
    this.authService
      .resetPassword(
        this.email,
        this.password,
        this.confirmPassword,
        this.token
      )
      .subscribe(
        (response) => {
          console.log(response);
          if (response.success) {
            this.router.navigate(["/"]);
          }
        },
        (error) => {
          error = error?.message || "An error occurred during reset password.";
        }
      );
  }
}
